import { getResponse } from "../common/api.service";
import types from "../common/types";

export interface Leave {
  leave_id: number;
  student_id: number;
  student_code: string;
  student_name: string;
  school_id: number;
  section_id: number;
  leave_from_date: string;
  leave_to_date: string;
  leave_duration: number;
  leave_reason: string;
}

export interface LeavePayload {
  data: {
    leave: any;
  };
}

export const addLeave = getResponse(
  "student_leaves/addLeave",
  "/admin/transaction/leave/student/add",
  types.request.Add
);

export const updateLeave = getResponse(
  "student_leaves/updateLeave",
  "/admin/transaction/leave/student/update",
  types.request.Update
);

export const deleteLeave = getResponse(
  "student_leaves/deleteLeave",
  "/admin/transaction/leave/student/delete",
  types.request.Delete
);

export const getLeaveById = getResponse(
  "student_leaves/getLeaveById",
  "/admin/transaction/leave/student/find",
  types.request.Find,
  "leave"
);

export const getLeaves = getResponse(
  "student_leaves/getLeaves",
  "/admin/transaction/leave/student/list",
  types.request.List,
  "leave"
);
