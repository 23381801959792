import { FC, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { Chart as ChartJS, registerables } from "chart.js";
import { Formik, FormikHelpers, Form as FormikForm } from "formik";
import * as Yup from "yup";

import BreadcrumbHeader from "../../../components/common/BreadcrumbHeader";
import {
  BasicTable,
  Column,
} from "../../../components/tables/DataTables/Basictable";
import { getStudentAttendanceSummary } from "../studentAttendanceService";
import { formatDateToYYYYMMDD } from "../../students/studentService";
import TodaysActivityCard from "../components/TodaysActivityCard";
import StudentAttendanceCard from "../components/StudentAttendanceCard";
import StudentAttendanceStatisticCard from "../components/StudentAttendanceStatisticCard";
import { FormSelect, SelectOptionProps } from "../../../components/forms";
import moment from "moment";

const COLUMNS: Column[] = [
  {
    header: "Date",
    accessor: "attendance_date",
    className: "borderrigth",
    Cell: ({ row }: any) => (
      <span className="w-100 text-center">
        {formatDateToYYYYMMDD(new Date(row.original.attendance_date))}
      </span>
    ),
  },
  {
    header: "In Time",
    accessor: "in_time",
    className: "borderrigth",
  },

  {
    header: "Out Time",
    accessor: "out_time",
    className: "borderrigth text-center",
    Cell: ({ row }: any) => (
      <span className="w-100 text-center">{row.original.out_time}</span>
    ),
  },

  {
    header: "Status",
    accessor: "is_present",
    className: "borderrigth text-center",
    Cell: ({ row }: any) => (
      <span className="w-100 text-center">
        {row.original.is_present == 1 ? "Present" : "Absent"}
      </span>
    ),
  },
  {
    header: "Leave",
    accessor: "student_leave",
    className: "borderrigth text-center",
    Cell: ({ row }: any) => (
      <span className="w-100 text-center">{row.original.student_leave}</span>
    ),
  },
  {
    header: "In Late",
    accessor: "in_late",
    className: "borderrigth text-center",
    Cell: ({ row }: any) => (
      <span className="text-center">{row.original.in_late}</span>
    ),
  },
  {
    header: "Early Out",
    accessor: "early_out",
    className: "borderrigth text-center",
    Cell: ({ row }: any) => (
      <span className="text-center">{row.original.early_out}</span>
    ),
  },
];

ChartJS.register(...registerables);
const StudentAttendanceIndexScreen: FC = () => {
  const { id } = useParams();
  const [attendance, setAttendance] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [error, setError] = useState<any>(null);
  const [attendanceLoading, setAttendanceLoading] = useState(true);
  //fromDate should be first day of the current month
  const [fromDate, setFromDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [year, setYear] = useState(moment().format("YYYY"));
  const [month, setMonth] = useState(moment().format("MM"));
  const yearOptions: SelectOptionProps[] = [
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
  ];
  const monthOptions: SelectOptionProps[] = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const breadcrumbItems = [
    { text: "Student", href: "/attendances/students" },
    { text: "Attendance", isActive: true },
  ];
  useEffect(() => {
    // if (error != null) {
    //   toast.error(error?.message);
    // }
    // alert("fromDate: " + fromDate + " toDate: " + toDate + " id: " + id);
    setAttendanceLoading(true);
    getStudentAttendanceSummary(
      {
        attendance: {
          section_id: "290",
          //"student_id":3, //optional
          student_id: id,
          from_date: fromDate,
          to_date: toDate,
        },
      }
      // {
      //   attendance: {
      //     section_id: 2,
      //     student_id: 3, //optional
      //     from_date: "2023-08-01",
      //     to_date: "2023-08-30",
      //   },
      // }
    )
      .then((data) => {
        setAttendance(data.result.attendance);
        setAttendanceLoading(false);
        // console.log(data.result);
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
        setError(error);
        setAttendanceLoading(false);
      });
  }, [fromDate, toDate]);
  //console.log(attendance);
  const validationSchema = Yup.object({
    year: Yup.string().required("Required"),
    month: Yup.string().required("Required"),
  });
  return (
    <div>
      <ToastContainer />
      <BreadcrumbHeader
        mainContentTitle="Student Attendance"
        breadcrumbItems={breadcrumbItems}
      />
      <Row>
        <Col lg={4} md={4}>
          <StudentAttendanceCard
            studentId={id}
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
          />
        </Col>
        <Col lg={4} md={4}>
          <StudentAttendanceStatisticCard />
        </Col>
        <Col lg={4}>
          <TodaysActivityCard current_date={currentDate} id={id} />
        </Col>
        <Row></Row>
      </Row>
      <Row className=" row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Header className=" custom-card-header">
              <h6 className="card-title mb-0">Monthly Attendance</h6>
            </Card.Header>
            <Card.Body>
              <div className="table-responsive">
                <BasicTable
                  columns={COLUMNS}
                  data={attendance}
                  loading={attendanceLoading}
                  LeftComponent={
                    <Formik
                      initialValues={{
                        year,
                        month,
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        setAttendanceLoading(true);
                        setYear(values.year);
                        setMonth(values.month);
                        const firstDayOfMonth = moment(
                          `${values.year}-${values.month}-01`
                        );
                        const lastDayOfMonth = firstDayOfMonth
                          .clone()
                          .endOf("month");

                        setFromDate(firstDayOfMonth.format("YYYY-MM-DD"));
                        setToDate(lastDayOfMonth.format("YYYY-MM-DD"));
                      }}
                      // onChange={(e: any) => {
                      //   console.log(e);
                      // }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      }) => (
                        <FormikForm noValidate onSubmit={handleSubmit}>
                          <Row className="row-xs align-items-center">
                            <FormSelect
                              // label="Select Year"
                              name="year"
                              values={values}
                              errors={errors}
                              touched={touched}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              input_md={4}
                              placeholder="Enter Year"
                              options={yearOptions}
                            />
                            <FormSelect
                              // label="Select Month"
                              name="month"
                              values={values}
                              errors={errors}
                              touched={touched}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              input_md={4}
                              options={monthOptions}
                              placeholder="Month"
                            />
                            <Col md={4} className="pull-right">
                              <Button
                                type="submit"
                                className="btn btn-sm btn-primary"
                              >
                                Search
                              </Button>
                            </Col>
                          </Row>
                        </FormikForm>
                      )}
                    </Formik>
                  }
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default StudentAttendanceIndexScreen;
