import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  Amendment,
  addAmendment,
  deleteAmendment,
  getAmendments,
  getAmendmentById,
  updateAmendment,
} from "./studentAmendmentService";

interface AmendmentState {
  list: {
    loading: boolean;
    error: any | null;
    amendments: Amendment[] | [];
  };
  save: {
    loading: boolean;
    success: boolean;
    error: any | null;
    amendment: Amendment | null;
  };
  delete: {
    loading: boolean;
    success: boolean;
    error: any | null;
  };
}
const initialState = {
  list: {
    loading: false,
    success: false,
    error: null,
    amendments: [],
  },
  save: {
    loading: false,
    success: false,
    error: null,
    amendment: null,
  },
  delete: {
    loading: false,
    success: false,
    error: null,
  },
} as AmendmentState;

const studentAmendmentSlice = createSlice({
  name: "student_amendments",
  initialState,
  reducers: {
    resetList: (state) => {
      state.list.loading = false;
      state.list.error = null;
      state.list.amendments = [];
    },
    resetSave: (state) => {
      state.save.loading = false;
      state.save.success = false;
      state.save.error = null;
      state.save.amendment = null;
    },
    resetDelete: (state) => {
      state.delete.loading = false;
      state.delete.success = false;
      state.delete.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAmendments.pending, (state, action) => {
        state.list.loading = true;
      })
      .addCase(
        getAmendments.fulfilled,
        (state, action: PayloadAction<Amendment[]>) => {
          state.list.loading = false;
          state.list.error = null;
          state.list.amendments = action.payload;
        }
      )
      .addCase(getAmendments.rejected, (state, action: PayloadAction<any>) => {
        state.list.loading = false;
        state.list.amendments = [];
        state.list.error = action.payload;
      })
      .addCase(addAmendment.pending, (state, action) => {
        state.save.loading = true;
      })
      .addCase(addAmendment.fulfilled, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = true;
        state.save.error = null;
        state.save.amendment = action.payload;
      })
      .addCase(addAmendment.rejected, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = false;
        state.save.error = action.payload;
        state.save.amendment = null;
      })
      .addCase(getAmendmentById.pending, (state, action) => {
        state.save.loading = true;
      })
      .addCase(
        getAmendmentById.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.save.loading = false;
          state.save.error = null;
          state.save.amendment = action.payload;
        }
      )
      .addCase(
        getAmendmentById.rejected,
        (state, action: PayloadAction<any>) => {
          state.save.loading = false;
          state.save.error = action.payload;
          state.save.amendment = null;
        }
      )
      .addCase(deleteAmendment.pending, (state, action) => {
        state.delete.loading = true;
      })
      .addCase(
        deleteAmendment.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.delete.loading = false;
          state.delete.success = true;
          state.delete.error = null;
        }
      )
      .addCase(
        deleteAmendment.rejected,
        (state, action: PayloadAction<any>) => {
          state.delete.loading = false;
          state.delete.success = false;
          state.delete.error = action.payload;
        }
      )
      .addCase(updateAmendment.pending, (state, action) => {
        state.save.loading = true;
      })
      .addCase(
        updateAmendment.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.save.loading = false;
          state.save.success = true;
          state.save.error = null;
          state.save.amendment = action.payload;
        }
      )
      .addCase(
        updateAmendment.rejected,
        (state, action: PayloadAction<any>) => {
          state.save.loading = false;
          state.save.success = false;
          state.save.error = action.payload;
          state.save.amendment = null;
        }
      );
  },
});

export const selectCount = (state: RootState) => state.student_amendment;

export const { resetList, resetSave, resetDelete } =
  studentAmendmentSlice.actions;

export const studentAmendmentReducer = studentAmendmentSlice.reducer;
