import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  Student,
  addStudent,
  deleteStudent,
  getStudentById,
  getStudents,
  updateStudent,
} from "./studentService";

interface StudentState {
  list: {
    loading: boolean;
    error: any | null;
    students: Student[];
  };
  save: {
    loading: boolean;
    success: boolean;
    error: any | null;
    student: any | null;
  };
  delete: {
    loading: boolean;
    success: boolean;
    error: any | null;
  };
}
const initialState = {
  list: {
    loading: false,
    error: null,
    students: [],
  },
  save: {
    loading: false,
    success: false,
    error: null,
    student: null,
  },
  delete: {
    loading: false,
    success: false,
    error: null,
  },
} as StudentState;

const studentSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    resetList: (state) => {
      state.list.loading = false;
      state.list.error = null;
      state.list.students = [];
    },
    resetSave: (state) => {
      state.save.loading = false;
      state.save.success = false;
      state.save.error = null;
      state.save.student = null;
    },
    resetDelete: (state) => {
      state.delete.loading = false;
      state.delete.success = false;
      state.delete.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStudents.pending, (state, action) => {
        state.list.loading = true;
      })
      .addCase(
        getStudents.fulfilled,
        (state, action: PayloadAction<Student[]>) => {
          state.list.loading = false;
          state.list.error = null;
          state.list.students = action.payload;
        }
      )
      .addCase(getStudents.rejected, (state, action: PayloadAction<any>) => {
        state.list.loading = false;
        state.list.students = [];
        state.list.error = action.payload;
      })
      .addCase(addStudent.pending, (state, action) => {
        state.save.loading = true;
      })
      .addCase(addStudent.fulfilled, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = true;
        state.save.error = null;
        state.save.student = action.payload;
      })
      .addCase(addStudent.rejected, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = false;
        state.save.error = action.payload;
        //state.save.student = null;
      })
      .addCase(getStudentById.pending, (state, action) => {
        state.save.loading = true;
      })
      .addCase(
        getStudentById.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.save.loading = false;
          state.save.error = null;
          state.save.student = action.payload;
        }
      )
      .addCase(getStudentById.rejected, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.error = action.payload;
        state.save.student = null;
      })
      .addCase(deleteStudent.pending, (state, action) => {
        state.delete.loading = true;
      })
      .addCase(deleteStudent.fulfilled, (state, action: PayloadAction<any>) => {
        state.delete.loading = false;
        state.delete.success = true;
        state.delete.error = null;
      })
      .addCase(deleteStudent.rejected, (state, action: PayloadAction<any>) => {
        state.delete.loading = false;
        state.delete.success = false;
        state.delete.error = action.payload;
      })
      .addCase(updateStudent.pending, (state, action) => {
        state.save.loading = true;
      })
      .addCase(updateStudent.fulfilled, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = true;
        state.save.error = null;
        state.save.student = action.payload;
      })
      .addCase(updateStudent.rejected, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = false;
        state.save.error = action.payload;
        state.save.student = null;
      });
  },
});

export const selectCount = (state: RootState) => state.student;

export const { resetList, resetSave, resetDelete } = studentSlice.actions;

export const studentReducer = studentSlice.reducer;
