import { FC, useEffect } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Formik, Form as FormikForm, FormikHelpers } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import BreadcrumbHeader from "../../../components/common/BreadcrumbHeader";
import {
  FormSelect,
  FormTextarea,
  FormDatepicker,
} from "../../../components/forms";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { resetSave } from "../../../features/studentLeaves/studentLeaveSlice";
import {
  getLovAmendtreat,
  getLovAmendtype,
} from "../../../features/lov/lovService";
import { addLeave } from "../studentLeaveService";
import {
  formatDateToYYYYMMDD,
  getStudents,
} from "../../students/studentService";
import { formatTime } from "../../shifts/shiftService";
// "leave": [
//   {
//       "student_id": 2,
//       "section_id": 290,
//       "leave_from_date": "2023-09-05",
//       "leave_to_date": "2023-09-06",
//       "leave_reason": "Sick"
//   }
// ]
interface Values {
  student_id: number;
  section_id: number;
  leave_from_date: Date;
  leave_to_date: Date;
  leave_reason: string;
}
const initialValues: Values = {
  student_id: 0,
  section_id: 0,
  leave_from_date: new Date(),
  leave_to_date: new Date(),
  leave_reason: "",
};
const validationSchema = Yup.object({
  student_id: Yup.number().required("Required"),
  // section_id: Yup.number().required("Required"),
  leave_from_date: Yup.date().required("Required"),
  leave_to_date: Yup.date().required("Required"),
  leave_reason: Yup.string().required("Required"),
});

const LeaveEntryScreen: FC = () => {
  const dispatch = useAppDispatch();
  const { students, loading: studentLoading } = useAppSelector(
    (state) => state.student.list
  );
  const { loading: loadingSave } = useAppSelector(
    (state) => state.student_leaves.save
  );
  const breadcrumbItems = [
    { text: "Leave", href: "/students/leaves" },
    { text: "Entry", isActive: true },
  ];

  useEffect(() => {
    if (students.length === 0) {
      dispatch(
        getStudents({
          student: {},
        })
      );
    }
  }, [dispatch]);

  const handleSubmit = (
    values: Values,
    { resetForm }: FormikHelpers<Values>
  ) => {
    const payload: any = {
      data: {
        leave: [
          {
            student_id: values.student_id,
            section_id:
              students.find(
                (item: any) => item.student_id === values.student_id
              )?.running_section || 0,
            leave_from_date: formatDateToYYYYMMDD(values.leave_from_date),
            leave_to_date: formatDateToYYYYMMDD(values.leave_to_date),
            leave_reason: values.leave_reason,
          },
        ],
      },
    };
    dispatch(addLeave(payload))
      // .unwrap()
      .then(() => {
        resetForm();
        toast("Successful!");
        dispatch(resetSave());
      })
      .catch((error) => {
        toast.error(error.message);
      });
    resetForm();
  };
  return (
    <div>
      <ToastContainer />
      <BreadcrumbHeader
        mainContentTitle="Leave Info"
        breadcrumbItems={breadcrumbItems}
      />
      <Col lg={6} md={6}>
        <Card>
          <Card.Body>
            <div className="main-content-label mg-b-5">Leave Entry</div>
            <div className="pd-30 pd-sm-20">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <FormikForm noValidate onSubmit={handleSubmit}>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormSelect
                        label="Student"
                        name="student_id"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        loading={studentLoading}
                        options={students.map((item: any) => {
                          return {
                            value: item.student_id,
                            label: item.student_name,
                          };
                        })}
                        placeholder="Select Student"
                      />
                    </Row>

                    <Row className="row-xs align-items-center mg-b-5">
                      <FormDatepicker
                        label="From Date"
                        name="leave_from_date"
                        dateFormat="yyyy-mm-dd"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={null}
                        handleBlur={handleBlur}
                      />
                    </Row>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormDatepicker
                        label="From Date"
                        name="leave_to_date"
                        dateFormat="yyyy-mm-dd"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={null}
                        handleBlur={handleBlur}
                      />
                    </Row>

                    <Row className="row-xs align-items-center mg-b-5">
                      <FormTextarea
                        label="Reason"
                        name="leave_reason"
                        values={values}
                        errors={errors}
                        row={2}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        placeholder="Enter reason"
                      />
                    </Row>
                    <Row>
                      <Col md={3}></Col>
                      <Col md={9} className=" mg-t-5 mg-md-t-0 pull-right">
                        <Button
                          disabled={loadingSave}
                          type="submit"
                          className="btn btn-primary pd-x-30 mg-r-5 mg-t-5"
                        >
                          {loadingSave ? "Saving..." : "Save"}
                        </Button>
                      </Col>
                    </Row>
                  </FormikForm>
                )}
              </Formik>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default LeaveEntryScreen;
