import { FC, useEffect } from "react";
import { Card, Col, Row, Button, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";

import BreadcrumbHeader from "../../components/common/BreadcrumbHeader";
import {
  getSectionById,
  updateSection,
  deleteSection,
} from "../../features/sections/sectionService";
import { resetSave, resetDelete } from "../../features/sections/sectionSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import {
  getLovClass,
  getLovBranch,
  getLovShift,
  getLovTeacher,
} from "../../features/lov/lovService";
import {
  FormInput,
  FormTextarea,
  FormCheckBox,
  SelectOptionProps,
  FormSelect,
} from "../../components/forms";
import { ToastContainer, toast } from "react-toastify";

import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { getClass } from "../../features/classes/classService";
import { getShifts } from "../../features/shifts/shiftService";
import { CardSkeleton } from "../../components/common/skeletons/CardSkeleton";

interface Values {
  section_id: number;
  section_name: string;
  branch_id: number;
  class_id: number;
  shift_id: number;
  hr_id: number;
  section_room_number: number;
  section_description: string;
  is_active: boolean;
}

const validationSchema = Yup.object({
  section_id: Yup.number().required("Required"),
  section_name: Yup.string().required("Required"),
  branch_id: Yup.string().required("Required"),
  class_id: Yup.string().required("Required"),
  shift_id: Yup.string().required("Required"),
  hr_id: Yup.string().required("Required"),
  // teacher_id: Yup.string().required("Required"),
  section_room_number: Yup.string().required("Required"),
  section_description: Yup.string().nullable(),
  is_active: Yup.boolean().required("Required"),
});
const SectionEditScreen: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, error, success, section } = useAppSelector(
    (state) => state.section.save
  );
  const { loading: deleteLoading, success: deleteSucces } = useAppSelector(
    (state) => state.section.delete
  );
  const { classList, branchList, shiftList, teacherList } = useAppSelector(
    (state) => state.lov
  );
  useEffect(() => {
    if (classList.classes.length === 0) {
      dispatch(getLovClass({}));
    }
    if (shiftList.shifts.length === 0) {
      dispatch(getLovShift({}));
    }
    if (branchList.branches.length === 0) {
      dispatch(getLovBranch({}));
    }
    if (teacherList.teachers.length === 0) {
      dispatch(getLovTeacher({}));
    }
  }, [dispatch]);
  const branchOptions: SelectOptionProps[] = [
    { value: "1", label: "Branch 1" },
    { value: "2", label: "Branch 2" },
    { value: "3", label: "Branch 3" },
  ];
  // const teacherOptions: SelectOptionProps[] = [
  //   { value: "1", label: "Teacher 1" },
  //   { value: "2", label: "Teacher 2" },
  //   { value: "3", label: "Teacher 3" },
  // ];
  const hrOptions: SelectOptionProps[] = [
    { value: "1", label: "HR 1" },
    { value: "2", label: "HR 2" },
    { value: "3", label: "HR 3" },
  ];

  useEffect(() => {
    if (error != null) {
      toast.error(error.message);
    }
    if (success) {
      dispatch(resetSave());
      toast("Successful!");
      //navigate("/classes/list");
    } else {
      dispatch(getSectionById({ section: { section_id: id } }))
        .unwrap()
        //.then((response) => {})
        .catch((er) => {
          toast.error(er.message);
        });
      dispatch(
        getClass({
          class: {},
        })
      );
      dispatch(
        getShifts({
          shift: {},
        })
      );
    }
  }, [dispatch, id, success, navigate]);

  useEffect(() => {
    if (deleteSucces) {
      dispatch(resetDelete());
      swalWithBootstrapButtons
        .fire("Deleted!", "Deleted section Successfully.", "success")
        .then((result) => {
          if (result.isConfirmed) {
            navigate("/sections/list");
          }
        });
    }
  }, [dispatch, deleteSucces, navigate]);

  const breadcrumbItems = [
    { text: "Section", href: "/sections/list" },
    { text: "Edit", isActive: true },
  ];

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  const deleteAlert = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "Your will not be able to recover this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(
            deleteSection({
              data: {
                section: [
                  {
                    section_id: id,
                  },
                ],
              },
            })
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
  };
  return (
    <div>
      <ToastContainer />
      <BreadcrumbHeader
        mainContentTitle="Shift Info"
        breadcrumbItems={breadcrumbItems}
      />
      <Col lg={6} md={6}>
        <Card>
          <Card.Body>
            <div className="main-content-label mg-b-5">Section Edit</div>
            <div className="pd-30 pd-sm-20">
              {loading ? (
                <CardSkeleton skeletonNumberOfRows={7} />
              ) : (
                <Formik
                  validationSchema={validationSchema}
                  initialValues={{
                    section_id: section?.section_id || 0,
                    section_name: section?.section_name || "",
                    branch_id: section?.branch_id || 0,
                    class_id: section?.class_id || 0,
                    shift_id: section?.shift_id || 0,
                    hr_id: section?.hr_id || 0,
                    section_room_number: section?.section_room_number || 0,
                    section_description: section?.section_description || "",
                    is_active: section?.is_active === 1 ? true : false,
                  }}
                  onSubmit={(values: Values) => {
                    console.log(values);
                    dispatch(
                      updateSection({
                        data: {
                          section: [
                            {
                              section_id: values.section_id,
                              section_name: values.section_name,
                              branch_id: values.branch_id,
                              class_id: values.class_id,
                              shift_id: values.shift_id,
                              hr_id: values.hr_id,
                              section_room_number: values.section_room_number,
                              section_description: values.section_description,
                              is_active: values.is_active ? 1 : 0,
                            },
                          ],
                        },
                      })
                    );
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <FormikForm noValidate onSubmit={handleSubmit}>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormInput
                          label="Section Name"
                          name="section_name"
                          type="text"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          placeholder="Enter section name"
                        />
                      </Row>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormSelect
                          label="Branch"
                          name="branch_id"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          options={branchList.branches.map((item: any) => {
                            return {
                              value: item.branch_id,
                              label: item.branch_name,
                            };
                          })}
                          placeholder="Select branch"
                        />
                      </Row>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormSelect
                          label="Class"
                          name="class_id"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          options={classList.classes.map((item: any) => {
                            return {
                              value: item.class_id,
                              label: item.class_name,
                            };
                          })}
                          loading={classList.loading}
                        />
                      </Row>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormSelect
                          label="Shift"
                          name="shift_id"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          options={shiftList.shifts.map((item: any) => {
                            return {
                              value: item.shift_id,
                              label: item.shift_name,
                            };
                          })}
                          loading={classList.loading}
                        />
                      </Row>
                      {/* <Row className="row-xs align-items-center mg-b-5">
                      <FormSelect
                        label="Teacher"
                        name="teacher_id"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        options={teacherOptions}
                        placeholder="Select teacher"
                      />
                    </Row> */}
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormSelect
                          label="HR"
                          name="hr_id"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          options={teacherList.teachers.map((item: any) => {
                            return {
                              value: item.teacher_id,
                              label: item.teacher_name,
                            };
                          })}
                          loading={classList.loading}
                          placeholder="Select teacher"
                        />
                      </Row>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormInput
                          label="Room Number"
                          name="section_room_number"
                          type="number"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          placeholder="Enter room number"
                        />
                      </Row>
                      {/* <Row className="row-xs align-items-center mg-b-5">
                      <FormSelect
                        label="Section Order"
                        name="section_order"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        options={sectionOrderOptions}
                        placeholder="Select section order"
                      />
                    </Row> */}
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormTextarea
                          label="Description"
                          name="section_description"
                          values={values}
                          errors={errors}
                          row={2}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          placeholder="Enter description"
                        />
                      </Row>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormCheckBox
                          label="Active"
                          name="is_active"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      </Row>
                      <Row>
                        <Col md={3}></Col>
                        <Col md={9} className=" mg-t-5 mg-md-t-0 pull-right">
                          <Button
                            disabled={loading}
                            type="submit"
                            className="btn btn-primary pd-x-30 mg-r-5 mg-t-5"
                          >
                            {loading ? "Loading..." : "Save"}
                          </Button>
                          <Button
                            disabled={deleteLoading}
                            onClick={deleteAlert}
                            className="btn btn-danger pd-x-30 mg-r-5 mg-t-5"
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    </FormikForm>
                  )}
                </Formik>
              )}
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default SectionEditScreen;
