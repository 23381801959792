import { getResponse } from "../common/api.service";
import types from "../common/types";

export interface ClassData {
  class_id: number;
  class_name: string;
  class_lavel: number;
  school_id: number;
  class_description: string;
  create_date: string;
  create_by: number;
  update_date: string;
  update_by: string;
  is_active: number;
}

export interface ClassPayload {
  data: {
    class: any;
  };
}

export const addClass = getResponse(
  "class/addClass",
  "/admin/base/class/add",
  types.request.Update
);

export const updateClass = getResponse(
  "class/updateClass",
  "/admin/base/class/update",
  types.request.Update
);

export const deleteClass = getResponse(
  "class/deleteClass",
  "/admin/base/class/delete",
  types.request.Delete
);

export const getClassById = getResponse(
  "class/getClassById",
  "/admin/base/class/find",
  types.request.Find,
  "class"
);

export const getClass = getResponse(
  "class/getClass",
  "/admin/base/class/list",
  types.request.List,
  "class"
);
