import { getResponse } from "../common/api.service";
import types from "../common/types";

export interface Amendment {
  amend_id: number;
  student_id: number;
  student_code: string;
  student_name: string;
  school_id: number;
  section_id: number;
  amend_type: string;
  amend_treat: string;
  amended_date: string;
  in_time_log: string;
  out_time_log: string;
  amend_reason: string;
}

export interface AmendmentPayload {
  data: {
    amendment: any;
  };
}

export const addAmendment = getResponse(
  "amendment/addAmendment",
  "/admin/transaction/amendment/student/add",
  types.request.Add
);

export const updateAmendment = getResponse(
  "amendment/updateAmendment",
  "/admin/transaction/amendment/student/update",
  types.request.Update
);

export const deleteAmendment = getResponse(
  "amendment/deleteAmendment",
  "/admin/transaction/amendment/student/delete",
  types.request.Delete
);

export const getAmendmentById = getResponse(
  "amendment/getAmendmentById",
  "/admin/transaction/amendment/student/find",
  types.request.Find,
  "amendment"
);

export const getAmendments = getResponse(
  "amendment/getAmendments",
  "/admin/transaction/amendment/student/list",
  types.request.List,
  "amendment"
);
