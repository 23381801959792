import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../app/axiosConfig";
import types from "./types";

type ResponseType = {
  response_code: string;
  http_code: number;
  message: string;
  result: {};
};

// Define the asynchronous action creator
export function getResponse(
  prefix: string,
  endpoint: string,
  reqType: string,
  key: string = ""
) {
  return createAsyncThunk<[], {}>(prefix, async (payload, thunkApi) => {
    try {
      const response = await axios.post<ResponseType>(endpoint, payload);

      const data: any = response.data.result;
      //console.log("api.service data: ", data);
      if (reqType === types.request.Find) return data[key][0];
      else if (reqType === types.request.List) return data[key];
      else return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
      const message = error.message;
      //console.log("api.service error: ", error);

      return thunkApi.rejectWithValue(message);
    }
  });
}

export async function getApiResponse(
  endpoint: string,
  reqType: string,
  key: string = "",
  payload: any // Define your payload type here
) {
  try {
    const response = await axios.post<ResponseType>(endpoint, payload);

    const data: any = response.data.result;
    if (reqType === "Find") return data[key][0];
    else if (reqType === "List") return data[key];
    else return response.data;
  } catch (error: any) {
    if (error?.response?.status === 401) {
      localStorage.clear();
      window.location.href = "/";
    }
    const message = error.message;
    throw new Error(message);
  }
}
