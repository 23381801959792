import { FC, useEffect, useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import BreadcrumbHeader from "../../components/common/BreadcrumbHeader";
import { ClassPayload, addClass } from "../../features/classes/classService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, FormikHelpers, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { resetSave } from "../../features/classes/classSlice";

import {
  FormInput,
  FormSelect,
  FormTextarea,
  SelectOptionProps,
  FormCheckBox,
} from "../../components/forms";

interface Values {
  class_name: string;
  level_id: string;
  description: string;
  is_active: boolean;
}

const validationSchema = Yup.object({
  class_name: Yup.string().required("Required"),
  level_id: Yup.string().required("Required"),
  description: Yup.string().nullable(),
  is_active: Yup.boolean().required("Required"),
});

//   const notify = () => toast("Wow so easy !");

//   return (
//     <div>
//       <button onClick={notify}>Notify !</button>
//       <ToastContainer />
//     </div>
//   );
// };
// export default ClassEntryScreen;

const ClassEntryScreen: FC = () => {
  const dispatch = useAppDispatch();
  const [initialValues, setInitialValues] = useState<Values>({
    class_name: "",
    level_id: "",
    description: "",
    is_active: true,
  });
  const { loading, error, success } = useAppSelector(
    (state) => state.class.save
  );
  const breadcrumbItems = [
    { text: "Classes", href: "/classes/list" },
    { text: "Entry", isActive: true },
  ];
  useEffect(() => {
    if (error != null) {
      toast.error(error.message);
    }
    // if (success) {
    //   toast("Successful!");
    //   dispatch(resetSave());
    //   //navigate("/classes/list");
    // }
  }, [dispatch]);

  const handleSubmit = (
    values: Values,
    { resetForm }: FormikHelpers<Values>
  ) => {
    const payload: ClassPayload = {
      data: {
        class: [
          {
            class_name: values.class_name,
            class_lavel: parseInt(values.level_id),
            class_description: values.description,
            is_active: values.is_active ? 1 : 0,
          },
        ],
      },
    };
    dispatch(addClass(payload))
      // .unwrap()
      .then(() => {
        resetForm();
        toast("Successful!");
        dispatch(resetSave());
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  return (
    <div>
      <ToastContainer />
      <BreadcrumbHeader
        mainContentTitle="Class Info"
        breadcrumbItems={breadcrumbItems}
      />
      <Col lg={6} md={6}>
        <Card>
          <Card.Body>
            <div className="main-content-label mg-b-5">Class Entry</div>
            <div className="pd-30 pd-sm-20">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <FormikForm noValidate onSubmit={handleSubmit}>
                    {/* <Row className="row-xs align-items-center mg-b-5">
                      <FormInput
                        disabled={true}
                        label="Class Id"
                        name="class_id"
                        type="text"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        placeholder="Enter class id"
                      />
                    </Row> */}
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormInput
                        label="Class Name"
                        name="class_name"
                        type="text"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        placeholder="Enter class name"
                      />
                    </Row>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormInput
                        type="number"
                        label="Level"
                        name="level_id"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        placeholder="Enter level"
                      />
                    </Row>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormTextarea
                        label="Description"
                        name="description"
                        values={values}
                        errors={errors}
                        row={2}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        placeholder="Enter description"
                      />
                    </Row>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormCheckBox
                        label="Active"
                        name="is_active"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    </Row>
                    <Row>
                      <Col md={3}></Col>
                      <Col md={9} className=" mg-t-5 mg-md-t-0 pull-right">
                        <Button
                          disabled={loading}
                          type="submit"
                          className="btn btn-primary pd-x-30 mg-r-5 mg-t-5"
                        >
                          {loading ? "Loading..." : "Save"}
                        </Button>
                      </Col>
                    </Row>
                  </FormikForm>
                )}
              </Formik>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default ClassEntryScreen;
