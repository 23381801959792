import { FC, useEffect } from "react";
import { Card, Col, Row, Button, Spinner } from "react-bootstrap";
import BreadcrumbHeader from "../../components/common/BreadcrumbHeader";
import {
  getShiftById,
  updateShift,
  deleteShift,
  parseTimeString,
  formatTime,
} from "../../features/shifts/shiftService";
import { resetSave, resetDelete } from "../../features/shifts/shiftSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Swal from "sweetalert2";

import {
  FormInput,
  FormTextarea,
  FormCheckBox,
  FormDatepicker,
} from "../../components/forms";
import { ToastContainer, toast } from "react-toastify";

import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { CardSkeleton } from "../../components/common/skeletons/CardSkeleton";

interface Values {
  shift_id: number;
  shift_name: string;
  shift_start_time: Date;
  shift_end_time: Date;
  shift_description: string;
  is_active: boolean;
}

const validationSchema = Yup.object({
  shift_id: Yup.number().required("Required"),
  shift_name: Yup.string().required("Required"),
  shift_start_time: Yup.string().required("Required"),
  shift_end_time: Yup.string().required("Required"),
  shift_description: Yup.string().nullable(),
  is_active: Yup.boolean().required("Required"),
});
const ShiftEditScreen: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, error, success, shift } = useAppSelector(
    (state) => state.shift.save
  );
  const { loading: deleteLoading, success: deleteSucces } = useAppSelector(
    (state) => state.shift.delete
  );
  useEffect(() => {
    if (error != null) {
      toast.error(error.message);
    }
    if (success) {
      dispatch(resetSave());
      toast("Successful!");
      //navigate("/classes/list");
    } else {
      dispatch(getShiftById({ shift: { shift_id: id } }))
        .unwrap()
        //.then((response) => {})
        .catch((er) => {
          toast.error(er.message);
        });
    }
  }, [dispatch, id, success, navigate]);

  useEffect(() => {
    if (deleteSucces) {
      dispatch(resetDelete());
      swalWithBootstrapButtons
        .fire("Deleted!", "Deleted Shift Successfully.", "success")
        .then((result) => {
          if (result.isConfirmed) {
            navigate("/shifts/list");
          }
        });
    }
  }, [dispatch, deleteSucces, navigate]);

  const breadcrumbItems = [
    { text: "Shifts", href: "/shifts/list" },
    { text: "Edit", isActive: true },
  ];

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  const deleteAlert = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "Your will not be able to recover this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(
            deleteShift({
              data: {
                shift: [
                  {
                    shift_id: id,
                  },
                ],
              },
            })
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
  };
  return (
    <div>
      <ToastContainer />
      <BreadcrumbHeader
        mainContentTitle="Shift Info"
        breadcrumbItems={breadcrumbItems}
      />
      <Col lg={6} md={6}>
        <Card>
          <Card.Body>
            <div className="main-content-label mg-b-5">Shift Edit</div>
            <div className="pd-30 pd-sm-20">
              {loading ? (
                <CardSkeleton skeletonNumberOfRows={7} />
              ) : (
                <Formik
                  validationSchema={validationSchema}
                  initialValues={{
                    shift_id: shift?.shift_id || 0,
                    shift_name: shift?.shift_name || "",
                    shift_start_time: shift
                      ? parseTimeString(shift?.shift_start_time)
                      : new Date(),
                    shift_end_time: shift
                      ? parseTimeString(shift?.shift_end_time)
                      : new Date(),
                    is_active: shift?.is_active === 1 ? true : false,
                    shift_description: shift?.shift_description || "",
                  }}
                  onSubmit={(values: Values) => {
                    // const { class_id, class_name, description, is_active } =
                    //   values;
                    console.log(values);
                    dispatch(
                      updateShift({
                        data: {
                          shift: [
                            {
                              shift_id: values.shift_id,
                              shift_name: values.shift_name,
                              shift_start_time: formatTime(
                                values.shift_start_time
                              ),
                              shift_end_time: formatTime(values.shift_end_time),
                              shift_description: values.shift_description,
                              is_active: values.is_active ? 1 : 0,
                            },
                          ],
                        },
                      })
                    );
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <FormikForm noValidate onSubmit={handleSubmit}>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormInput
                          label="Shift Name"
                          name="shift_name"
                          type="text"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          placeholder="Enter class name"
                        />
                      </Row>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormDatepicker
                          label="Start Time"
                          name="shift_start_time"
                          values={values}
                          errors={errors}
                          touched={touched}
                          // handleChange={handleChange}
                          handleBlur={handleBlur}
                          // initialDate={new Date()}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="hh:mm:ss"
                        />
                      </Row>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormDatepicker
                          label="End Time"
                          name="shift_end_time"
                          values={values}
                          errors={errors}
                          touched={touched}
                          // handleChange={handleChange}
                          handleBlur={handleBlur}
                          // initialDate={new Date()}
                          //dateFormat="h:mm aa"
                          dateFormat="hh:mm:ss"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                        />
                      </Row>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormTextarea
                          label="Description"
                          name="shift_description"
                          values={values}
                          errors={errors}
                          row={2}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          placeholder="Enter description"
                        />
                      </Row>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormCheckBox
                          label="Active"
                          name="is_active"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      </Row>
                      <Row>
                        <Col md={3}></Col>
                        <Col md={9} className=" mg-t-5 mg-md-t-0 pull-right">
                          <Button
                            disabled={loading}
                            type="submit"
                            className="btn btn-primary pd-x-30 mg-r-5 mg-t-5"
                          >
                            {loading ? "Loading..." : "Save"}
                          </Button>
                          <Button
                            disabled={deleteLoading}
                            onClick={deleteAlert}
                            className="btn btn-danger pd-x-30 mg-r-5 mg-t-5"
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    </FormikForm>
                  )}
                </Formik>
              )}
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default ShiftEditScreen;
