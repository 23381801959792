import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  Leave,
  addLeave,
  deleteLeave,
  getLeaves,
  getLeaveById,
  updateLeave,
} from "./studentLeaveService";

interface LeaveState {
  list: {
    loading: boolean;
    error: any | null;
    leaves: Leave[] | [];
  };
  save: {
    loading: boolean;
    success: boolean;
    error: any | null;
    leave: any | null;
  };
  delete: {
    loading: boolean;
    success: boolean;
    error: any | null;
  };
}
const initialState = {
  list: {
    loading: false,
    success: false,
    error: null,
    leaves: [],
  },
  save: {
    loading: false,
    success: false,
    error: null,
    leave: null,
  },
  delete: {
    loading: false,
    success: false,
    error: null,
  },
} as LeaveState;

const studentLeaveSlice = createSlice({
  name: "student_leaves",
  initialState,
  reducers: {
    resetList: (state) => {
      state.list.loading = false;
      state.list.error = null;
      state.list.leaves = [];
    },
    resetSave: (state) => {
      state.save.loading = false;
      state.save.success = false;
      state.save.error = null;
      state.save.leave = null;
    },
    resetDelete: (state) => {
      state.delete.loading = false;
      state.delete.success = false;
      state.delete.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLeaves.pending, (state, action) => {
        state.list.loading = true;
      })
      .addCase(getLeaves.fulfilled, (state, action: PayloadAction<Leave[]>) => {
        state.list.loading = false;
        state.list.error = null;
        state.list.leaves = action.payload;
      })
      .addCase(getLeaves.rejected, (state, action: PayloadAction<any>) => {
        state.list.loading = false;
        state.list.leaves = [];
        state.list.error = action.payload;
      })
      .addCase(addLeave.pending, (state, action) => {
        state.save.loading = true;
      })
      .addCase(addLeave.fulfilled, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = true;
        state.save.error = null;
        state.save.leave = action.payload;
      })
      .addCase(addLeave.rejected, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = false;
        state.save.error = action.payload;
        state.save.leave = null;
      })
      .addCase(getLeaveById.pending, (state, action) => {
        state.save.loading = true;
      })
      .addCase(getLeaveById.fulfilled, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.error = null;
        state.save.leave = action.payload;
      })
      .addCase(getLeaveById.rejected, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.error = action.payload;
        state.save.leave = null;
      })
      .addCase(deleteLeave.pending, (state, action) => {
        state.delete.loading = true;
      })
      .addCase(deleteLeave.fulfilled, (state, action: PayloadAction<any>) => {
        state.delete.loading = false;
        state.delete.success = true;
        state.delete.error = null;
      })
      .addCase(deleteLeave.rejected, (state, action: PayloadAction<any>) => {
        state.delete.loading = false;
        state.delete.success = false;
        state.delete.error = action.payload;
      })
      .addCase(updateLeave.pending, (state, action) => {
        state.save.loading = true;
      })
      .addCase(updateLeave.fulfilled, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = true;
        state.save.error = null;
        state.save.leave = action.payload;
      })
      .addCase(updateLeave.rejected, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = false;
        state.save.error = action.payload;
        state.save.leave = null;
      });
  },
});

export const selectCount = (state: RootState) => state.student_leaves;

export const { resetList, resetSave, resetDelete } = studentLeaveSlice.actions;

export const studentLeaveReducer = studentLeaveSlice.reducer;
