import { FC, useEffect, useState } from "react";
import { Formik, FormikHelpers, Form as FormikForm } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { resetSave } from "../studentSlice";
import {
  formatDateToYYYYMMDD,
  getStudentById,
  updateStudent,
} from "../studentService";

import Wizard from "./Wizard";
import ContactInfoSection from "./ContactInfoSection";
import GeneralInfoSection from "./GeneralInfoSection";
import AcademicInfoSection from "./AcademicInfoSection";
import OtherInfoSection from "./OtherInfoSection";
import { Spinner } from "react-bootstrap";
import { CardSkeleton } from "../../../components/common/skeletons/CardSkeleton";

const validationSchema = Yup.object({
  branch_id: Yup.number().required("Required"),
  student_code: Yup.string().required("Required"),
  student_name: Yup.string().required("Required"),
  short_name: Yup.string().required("Required"),
  student_dob: Yup.date().required("Required"),
  gender_id: Yup.number().required("Required"),
  nationality_id: Yup.number().required("Required"),
  religion_id: Yup.number().required("Required"),
  birth_certificate_no: Yup.string().required("Required"),
  blood_group_id: Yup.number().required("Required"),
  student_address: Yup.string().required("Required"),
  city_id: Yup.number().required("Required"),
  state_id: Yup.number().required("Required"),
  student_postal_code: Yup.string().required("Required"),
  country_id: Yup.number().required("Required"),
  student_rfid_number: Yup.string().required("Required"),
  registration_authorization_code: Yup.string().required("Required"),
  primary_conact_phone: Yup.string().required("Required"),
  primary_contact_email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  primary_relation: Yup.string().required("Required"),
  father_name: Yup.string().required("Required"),
  father_phone: Yup.string().required("Required"),
  father_email: Yup.string().email("Invalid email").required("Required"),
  mother_name: Yup.string().required("Required"),
  mother_phone: Yup.string().required("Required"),
  mother_email: Yup.string().email("Invalid email").required("Required"),
  emergency_contact_person: Yup.string().required("Required"),
  emergency_contact_phone: Yup.string().required("Required"),
  emergency_contact_email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  emergency_contact_relation: Yup.string().required("Required"),
  running_class: Yup.string().required("Required"),
  running_section: Yup.string().required("Required"),
  running_roll_number: Yup.string().required("Required"),
  running_session: Yup.string().required("Required"),
  student_photo_url: Yup.string().url("Invalid URL").nullable(),
  student_note: Yup.string().required("Required"),
  is_active: Yup.boolean().required("Required"),
  is_dorment: Yup.boolean().required("Required"),
});

const Step = ({ children }: any) => children;
interface StudentEditFormProps {
  id: any;
}

export const StudentEditFormWizard: FC<StudentEditFormProps> = ({ id }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, error, success, student } = useAppSelector(
    (state) => state.student.save
  );

  //update useEffect
  useEffect(() => {
    if (error != null) {
      toast.error(error.message);
    }
    if (success) {
      dispatch(resetSave());
      //toast("Successful!");
      //navigate("/classes/list");
    } else {
      dispatch(getStudentById({ student: { student_id: id } }))
        .unwrap()
        //.then((response) => {})
        .catch((er) => {
          toast.error(er.message);
        });
    }
  }, [dispatch, id, success, navigate]);

  const [step, setStep] = useState(0);
  const handleStep = (step: any) => {
    setStep(step);
  };

  const initialValues = {
    branch_id: 0,
    student_code: "",
    student_name: "",
    short_name: "",
    student_dob: "",
    gender_id: 0,
    nationality_id: 0,
    religion_id: 0,
    birth_certificate_no: "",
    blood_group_id: 0,
    student_address: "",
    city_id: 0,
    state_id: 0,
    student_postal_code: "",
    country_id: 0,
    student_rfid_number: "",
    registration_authorization_code: "",
    primary_conact_phone: "",
    primary_contact_email: "",
    primary_relation: "",
    father_name: "",
    father_phone: "",
    father_email: "",
    mother_name: "",
    mother_phone: "",
    mother_email: "",
    emergency_contact_person: "",
    emergency_contact_phone: "",
    emergency_contact_email: "",
    emergency_contact_relation: "",
    running_class: "",
    running_section: "",
    running_roll_number: "",
    running_session: "",
    student_photo_url:
      "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250",
    student_note: "",
    is_active: true,
    is_dorment: true,
  };

  const handleSubmit = (
    values: typeof initialValues,
    { resetForm }: FormikHelpers<typeof initialValues>
  ) => {
    const payload: any = {
      data: {
        student: [
          {
            student_id: id,
            branch_id: values.branch_id,
            student_code: values.student_code,
            student_name: values.student_name,
            short_name: values.short_name,
            student_dob: formatDateToYYYYMMDD(new Date(values.student_dob)),
            gender_id: values.gender_id,
            nationality_id: values.nationality_id,
            religion_id: values.religion_id,
            birth_certificate_no: values.birth_certificate_no,
            blood_group_id: values.blood_group_id,
            student_address: values.student_address,
            city_id: values.city_id,
            state_id: values.state_id,
            student_postal_code: values.student_postal_code,
            country_id: values.country_id,
            student_rfid_number: values.student_rfid_number,
            registration_authorization_code:
              values.registration_authorization_code,
            primary_conact_phone: values.primary_conact_phone,
            primary_contact_email: values.primary_contact_email,
            primary_relation: values.primary_relation,
            father_name: values.father_name,
            father_phone: values.father_phone,
            father_email: values.father_email,
            mother_name: values.mother_name,
            mother_phone: values.mother_phone,
            mother_email: values.mother_email,
            emergency_contact_person: values.emergency_contact_person,
            emergency_contact_phone: values.emergency_contact_phone,
            emergency_contact_email: values.emergency_contact_email,
            emergency_contact_relation: values.emergency_contact_relation,
            running_class: values.running_class,
            running_section: values.running_section,
            running_roll_number: values.running_roll_number,
            running_session: values.running_session,
            student_photo_url:
              "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250",
            student_note: values.student_note,
            is_active: values.is_active ? 1 : 0,
            is_dorment: values.is_dorment ? 1 : 0,
          },
        ],
      },
    };
    console.log(payload);
    dispatch(updateStudent(payload))
      // .unwrap()
      .then(() => {
        //resetForm();
        setStep(0);
        toast("Successful!");
        // dispatch(resetSave());
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return loading ? (
    <CardSkeleton skeletonHeight={25} skeletonNumberOfRows={10} />
  ) : (
    <Formik
      initialValues={{
        branch_id: student?.branch_id || 0,
        student_code: student?.student_code || "",
        student_name: student?.student_name || "",
        short_name: student?.short_name || "",
        student_dob:
          formatDateToYYYYMMDD(new Date(student?.student_dob)) ||
          formatDateToYYYYMMDD(new Date()),
        gender_id: student?.gender_id || "",
        nationality_id: student?.nationality_id || 0,
        religion_id: student?.religion_id || 0,
        birth_certificate_no: student?.birth_certificate_no || "",
        blood_group_id: student?.blood_group_id || 0,
        student_address: student?.student_address || "",
        city_id: student?.city_id || 0,
        state_id: student?.state_id || 0,
        student_postal_code: student?.student_postal_code || "",
        country_id: student?.country_id || 0,
        student_rfid_number: student?.student_rfid_number || "",
        registration_authorization_code:
          student?.registration_authorization_code || "",
        primary_conact_phone: student?.primary_conact_phone || "",
        primary_contact_email: student?.primary_contact_email || "",
        primary_relation: student?.primary_relation || "",
        father_name: student?.father_name || "",
        father_phone: student?.father_phone || "",
        father_email: student?.father_email || "",
        mother_name: student?.mother_name || "",
        mother_phone: student?.mother_phone || "",
        mother_email: student?.mother_email || "",
        emergency_contact_person: student?.emergency_contact_person || "",
        emergency_contact_phone: student?.emergency_contact_phone || "",
        emergency_contact_email: student?.emergency_contact_email || "",
        emergency_contact_relation: student?.emergency_contact_relation || "",
        running_class: student?.running_class || "",
        running_section: student?.running_section || "",
        running_roll_number: student?.running_roll_number || "",
        running_session: student?.running_session || "",
        student_photo_url: student?.student_photo_url || "",
        student_note: student?.student_note || "",
        is_active: student?.is_active || true,
        is_dorment: student?.is_dormant || true,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        console.log("Formik errors:", errors);
        return (
          <FormikForm noValidate onSubmit={handleSubmit}>
            <ToastContainer />
            <Wizard step={step} onChange={handleStep}>
              <Step title="General Information" number="1">
                <GeneralInfoSection
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Step>
              <Step title="Contact Information" number="2">
                <ContactInfoSection
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  countryId={values.country_id}
                  handleBlur={handleBlur}
                />
              </Step>
              <Step title="Academic" number="3">
                <AcademicInfoSection
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Step>
              <Step title="Other Info" number="4">
                <OtherInfoSection
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Step>
            </Wizard>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
