import { FC, useEffect } from "react";
import { Card, Col, Row, Button, Spinner } from "react-bootstrap";
import BreadcrumbHeader from "../../components/common/BreadcrumbHeader";
import {
  getClassById,
  updateClass,
  deleteClass,
} from "../../features/classes/classService";
import { resetSave, resetDelete } from "../../features/classes/classSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Swal from "sweetalert2";

import {
  FormInput,
  FormSelect,
  FormTextarea,
  SelectOptionProps,
  FormCheckBox,
} from "../../components/forms";
import { ToastContainer, toast } from "react-toastify";

import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { CardSkeleton } from "../../components/common/skeletons/CardSkeleton";

interface Values {
  class_id: number;
  class_name: string;
  level_id: number;
  description: string;
  is_active: boolean;
}

const validationSchema = Yup.object({
  class_id: Yup.number().required("Required"),
  class_name: Yup.string().required("Required"),
  level_id: Yup.string().required("Required"),
  description: Yup.string().nullable(),
  is_active: Yup.boolean().required("Required"),
});
const ClassEditScreen: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, error, success, classInfo } = useAppSelector(
    (state) => state.class.save
  );
  const { loading: deleteLoading, success: deleteSucces } = useAppSelector(
    (state) => state.class.delete
  );
  useEffect(() => {
    if (error != null) {
      toast.error(error.message);
    }
    if (success) {
      dispatch(resetSave());
      toast("Successful!");
      //navigate("/classes/list");
    } else {
      dispatch(getClassById({ class: { class_id: id } }))
        .unwrap()
        //.then((response) => {})
        .catch((er) => {
          console.log(er);
        });
    }
  }, [dispatch, id, success, navigate]);

  useEffect(() => {
    if (deleteSucces) {
      dispatch(resetDelete());
      swalWithBootstrapButtons
        .fire("Deleted!", "Deleted Class Successfully.", "success")
        .then((result) => {
          if (result.isConfirmed) {
            navigate("/classes/list");
          }
        });
    }
  }, [dispatch, deleteSucces, navigate]);

  const breadcrumbItems = [
    { text: "Classes", href: "/classes/list" },
    { text: "Edit", isActive: true },
  ];

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  const deleteAlert = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "Your will not be able to recover this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(
            deleteClass({
              data: {
                class: [
                  {
                    class_id: id,
                  },
                ],
              },
            })
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
  };
  return (
    <div>
      <ToastContainer />
      <BreadcrumbHeader
        mainContentTitle="Class Info"
        breadcrumbItems={breadcrumbItems}
      />
      <Col lg={6} md={6}>
        <Card>
          <Card.Body>
            <div className="main-content-label mg-b-5">Class Edit</div>
            <div className="pd-30 pd-sm-20">
              {loading ? (
                <CardSkeleton skeletonNumberOfRows={7} />
              ) : (
                <Formik
                  validationSchema={validationSchema}
                  initialValues={{
                    class_id: classInfo?.class_id || 0,
                    class_name: classInfo?.class_name || "",
                    level_id: classInfo?.class_lavel || 0,
                    is_active: classInfo?.is_active === 1 ? true : false,
                    description: classInfo?.class_description || "",
                  }}
                  onSubmit={(values: Values) => {
                    // const { class_id, class_name, description, is_active } =
                    //   values;
                    console.log(values);
                    dispatch(
                      updateClass({
                        data: {
                          class: [
                            {
                              class_id: values.class_id,
                              class_name: values.class_name,
                              class_lavel: values.level_id,
                              class_description: values.description,
                              is_active: values.is_active ? 1 : 0,
                            },
                          ],
                        },
                      })
                    );
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <FormikForm noValidate onSubmit={handleSubmit}>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormInput
                          disabled={true}
                          label="Class Id"
                          name="class_id"
                          type="text"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          placeholder="Enter class id"
                        />
                      </Row>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormInput
                          label="Class Name"
                          name="class_name"
                          type="text"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          placeholder="Enter class name"
                        />
                      </Row>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormInput
                          type="number"
                          label="Level"
                          name="level_id"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          placeholder="Enter level"
                        />
                      </Row>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormTextarea
                          label="Description"
                          name="description"
                          values={values}
                          errors={errors}
                          row={2}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          placeholder="Enter description"
                        />
                      </Row>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormCheckBox
                          label="Active"
                          name="is_active"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      </Row>
                      <Row>
                        <Col md={3}></Col>
                        <Col md={9} className=" mg-t-5 mg-md-t-0 pull-right">
                          <Button
                            disabled={loading}
                            type="submit"
                            className="btn btn-primary pd-x-30 mg-r-5 mg-t-5"
                          >
                            Save
                          </Button>
                          <Button
                            disabled={deleteLoading}
                            onClick={deleteAlert}
                            className="btn btn-danger pd-x-30 mg-r-5 mg-t-5"
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    </FormikForm>
                  )}
                </Formik>
              )}
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default ClassEditScreen;
