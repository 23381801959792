import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Card, Col, Row, Spinner, Button } from "react-bootstrap";
import { getClass } from "../../features/classes/classService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  BasicTable,
  Column,
} from "../../components/tables/DataTables/Basictable";
import BreadcrumbHeader from "../../components/common/BreadcrumbHeader";

const ClassIndexScreen = () => {
  const navigate = useNavigate();
  const handleEdit = (id: any) => {
    console.log(`Edit row with ID: ${id}`);
    navigate("/classes/edit/" + id);
  };

  const COLUMNS: Column[] = [
    {
      header: "Clss Name",
      accessor: "class_name",
      className: "wd-20p borderrigth",
    },
    {
      header: "Level",
      accessor: "class_lavel",
      className: "wd-25p borderrigth",
    },
    {
      header: "Description",
      accessor: "class_description",
      className: "wd-20p borderrigth",
    },
    {
      header: "Status",
      accessor: "is_active",
      className: "wd-15p borderrigth text-center",
      Cell: ({ row }: any) => (
        <span className="w-100 text-center">
          {row.original.is_active === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      header: "Actions",
      accessor: "id", // Assuming 'id' is a unique identifier for each row
      className: "action-column d-flex flex-row justify-content-center",
      Cell: ({ row }: any) => (
        <span className="w-100 text-center">
          <Button
            variant="primary"
            size="sm"
            onClick={() => handleEdit(row.original.class_id)}
          >
            <i className="fa fa-pen"></i>
          </Button>
        </span>
      ),
    },
  ];
  const { loading, error, classes } = useAppSelector(
    (state) => state.class.list
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (error != null) {
      toast.error(error.message);
    }
    dispatch(
      getClass({
        class: {},
      })
    );
  }, [dispatch, error]);

  console.log(classes);
  const breadcrumbItems = [
    { text: "Classes", href: "#" },
    { text: "All", isActive: true },
  ];
  const rightButton = (
    <Button
      variant="primary"
      className="mb-4 ml-2 btn btn-sm"
      onClick={() => navigate("/classes/entry")}
    >
      <span>
        <i className="fa fa-plus"></i>
        <span style={{ marginLeft: "5px !important" }}> New</span>
      </span>
    </Button>
  );
  return (
    <div>
      <BreadcrumbHeader
        mainContentTitle="Class Info"
        breadcrumbItems={breadcrumbItems}
      />
      <div className="main-container container-fluid">
        {/* <!-- Row --> */}
        <Row className=" row-sm">
          <Col lg={12}>
            <Card className="custom-card overflow-hidden">
              <Card.Body>
                <div>
                  <h6 className="main-content-label mb-1">All Classes</h6>
                </div>
                <div className="table-responsive">
                  <BasicTable
                    columns={COLUMNS}
                    data={classes}
                    RightButton={rightButton}
                    loading={loading}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <!-- End Row --> */}
      </div>
    </div>
  );
};

export default ClassIndexScreen;
