import { getResponse } from "../common/api.service";
import types from "../common/types";

export const formatDateToYYYYMMDD = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
export interface Student {
  student_id: number;
  branch_id: number;
  student_code: string;
  student_name: string;
  short_name: string;
  student_dob: Date; // Consider using Date type
  gender_id: number;
  nationality_id: number;
  religion_id: number;
  birth_certificate_no: string;
  blood_group_id: number;
  student_address: string;
  city_id: number;
  state_id: number;
  student_postal_code: string;
  country_id: number;
  student_rfid_number: string;
  registration_authorization_code: string;
  primary_conact_phone: string;
  primary_contact_email: string;
  primary_relation: string;
  father_name: string;
  father_phone: string;
  father_email: string;
  mother_name: string;
  mother_phone: string;
  mother_email: string;
  emergency_contact_person: string;
  emergency_contact_phone: string;
  emergency_contact_email: string;
  emergency_contact_relation: string;
  running_class: string;
  running_section: string;
  running_roll_number: string;
  running_session: string;
  student_photo_url: string;
  student_note: string;
  create_date: Date; // Consider using Date type
  create_by: number;
  update_date: Date; // Consider using Date type
  update_by: number;
  is_active: number;
  is_dormant: number;
}

export interface StudentPayload {
  data: {
    student: any;
  };
}

export const addStudent = getResponse(
  "student/addStudent",
  "/admin/base/student/add",
  types.request.Update
);

export const updateStudent = getResponse(
  "student/updateStudent",
  "/admin/base/student/update",
  types.request.Update
);

export const deleteStudent = getResponse(
  "shift/deleteStudent",
  "/admin/base/student/delete",
  types.request.Delete
);

export const getStudentById = getResponse(
  "student/getStudentById",
  "/admin/base/student/find",
  types.request.Find,
  "student"
);

export const getStudents = getResponse(
  "student/getStudents",
  "/admin/base/student/list",
  types.request.List,
  "student"
);
