import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { authReducer } from "../features/auth/authSlice";
import { classReducer } from "../features/classes/classSlice";
import { shiftReducer } from "../features/shifts/shiftSlice";
import { sectionReducer } from "../features/sections/sectionSlice";
import { lovReducer } from "../features/lov/lovSlice";
import { studentReducer } from "../features/students/studentSlice";
import { studentAmendmentReducer } from "../features/studentAmendments/studentAmendmentSlice";
import { studentLeaveReducer } from "../features/studentLeaves/studentLeaveSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    class: classReducer,
    shift: shiftReducer,
    section: sectionReducer,
    lov: lovReducer,
    student: studentReducer,
    student_amendment: studentAmendmentReducer,
    student_leaves: studentLeaveReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
