import { FC, Fragment } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ErrorMessage } from "formik";
import Skeleton from "react-loading-skeleton";
export interface SelectOptionProps {
  value: string;
  label: string;
}
export interface FormSelectProps {
  label?: string;
  name: string;
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
  options: SelectOptionProps[];
  disabled?: boolean;
  placeholder?: string;
  loading?: boolean;
  label_md?: number;
  input_md?: number;
  onCustomChange?: (selectedValue: string) => void;
}
export const FormSelect: FC<FormSelectProps> = ({
  disabled,
  label,
  name,
  errors,
  touched,
  values,
  options,
  placeholder,
  handleChange,
  handleBlur,
  loading,
  label_md,
  input_md,
  onCustomChange,
}) => {
  return (
    <Fragment>
      {label != null && (
        <Col className="mb-auto" md={label_md ?? 3}>
          <Form.Label className="form-label mb-auto">{label}</Form.Label>
        </Col>
      )}
      <Col md={input_md ?? 9} className=" mg-t-5 mg-md-t-0">
        <div className=" SlectBox">
          {loading ? (
            <Skeleton height={20} />
          ) : (
            <select
              className={`form-control form-control-sm ${
                errors[name] && touched[name] && "is-invalid"
              }`}
              disabled={disabled ? true : false}
              value={values[name]}
              onChange={(e) => {
                handleChange(e);
                if (onCustomChange) {
                  onCustomChange(e.target.value); // Call the custom onChange
                }
              }}
              onBlur={handleBlur}
              id={name}
              name={name}
              placeholder={placeholder}
            >
              <option value="">Select</option>
              {options.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          )}
        </div>
        <ErrorMessage name={name} component="div" className="text-danger" />
      </Col>
    </Fragment>
  );
};
//export multiple things from a file
