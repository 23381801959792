import { Card, Col } from "react-bootstrap";
import BreadcrumbHeader from "../../../components/common/BreadcrumbHeader";
import { SutentEntryFormWizard } from "../components/SutentEntryFormWizard";

const StudentEntryScreen = () => {
  const breadcrumbItems = [
    { text: "Student", href: "/students" },
    { text: "Entry", isActive: true },
  ];
  return (
    <div>
      <BreadcrumbHeader
        mainContentTitle="Student Information"
        breadcrumbItems={breadcrumbItems}
      />
      <Col className="mt-5" lg={12} md={12}>
        <Card>
          <Card.Body>
            <div id="wizard1" className="border">
              <SutentEntryFormWizard />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};
export default StudentEntryScreen;
