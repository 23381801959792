import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  Shift,
  addShift,
  deleteShift,
  getShifts,
  getShiftById,
  updateShift,
} from "./shiftService";

interface ShiftState {
  list: {
    loading: boolean;
    error: any | null;
    shifts: Shift[] | [];
  };
  save: {
    loading: boolean;
    success: boolean;
    error: any | null;
    shift: Shift | null;
  };
  delete: {
    loading: boolean;
    success: boolean;
    error: any | null;
  };
}
const initialState = {
  list: {
    loading: false,
    success: false,
    error: null,
    shifts: [],
  },
  save: {
    loading: false,
    success: false,
    error: null,
    shift: null,
  },
  delete: {
    loading: false,
    success: false,
    error: null,
  },
} as ShiftState;

const shiftSlice = createSlice({
  name: "classes",
  initialState,
  reducers: {
    resetList: (state) => {
      state.list.loading = false;
      state.list.error = null;
      state.list.shifts = [];
    },
    resetSave: (state) => {
      state.save.loading = false;
      state.save.success = false;
      state.save.error = null;
      state.save.shift = null;
    },
    resetDelete: (state) => {
      state.delete.loading = false;
      state.delete.success = false;
      state.delete.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShifts.pending, (state, action) => {
        state.list.loading = true;
      })
      .addCase(getShifts.fulfilled, (state, action: PayloadAction<Shift[]>) => {
        state.list.loading = false;
        state.list.error = null;
        state.list.shifts = action.payload;
      })
      .addCase(getShifts.rejected, (state, action: PayloadAction<any>) => {
        state.list.loading = false;
        state.list.shifts = [];
        state.list.error = action.payload;
      })
      .addCase(addShift.pending, (state, action) => {
        state.save.loading = true;
      })
      .addCase(addShift.fulfilled, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = true;
        state.save.error = null;
        state.save.shift = action.payload;
      })
      .addCase(addShift.rejected, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = false;
        state.save.error = action.payload;
        state.save.shift = null;
      })
      .addCase(getShiftById.pending, (state, action) => {
        state.save.loading = true;
      })
      .addCase(getShiftById.fulfilled, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.error = null;
        state.save.shift = action.payload;
      })
      .addCase(getShiftById.rejected, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.error = action.payload;
        state.save.shift = null;
      })
      .addCase(deleteShift.pending, (state, action) => {
        state.delete.loading = true;
      })
      .addCase(deleteShift.fulfilled, (state, action: PayloadAction<any>) => {
        state.delete.loading = false;
        state.delete.success = true;
        state.delete.error = null;
      })
      .addCase(deleteShift.rejected, (state, action: PayloadAction<any>) => {
        state.delete.loading = false;
        state.delete.success = false;
        state.delete.error = action.payload;
      })
      .addCase(updateShift.pending, (state, action) => {
        state.save.loading = true;
      })
      .addCase(updateShift.fulfilled, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = true;
        state.save.error = null;
        state.save.shift = action.payload;
      })
      .addCase(updateShift.rejected, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = false;
        state.save.error = action.payload;
        state.save.shift = null;
      });
  },
});

export const selectCount = (state: RootState) => state.section;

export const { resetList, resetSave, resetDelete } = shiftSlice.actions;

export const shiftReducer = shiftSlice.reducer;
