import { getResponse } from "../common/api.service";
import types from "../common/types";

export interface Section {
  section_id: number;
  section_name: string;
  school_id: number;
  branch_id: number;
  class_id: number;
  shift_id: number;
  hr_id: number;
  section_description: string;
  section_room_number: number;
  create_date: string;
  create_by: number;
  update_date: string;
  update_by: number;
  is_active: number;
}

export interface SectionPayload {
  data: {
    section: any;
  };
}

export const addSection = getResponse(
  "section/addSection",
  "/admin/base/section/add",
  types.request.Update
);

export const updateSection = getResponse(
  "section/updateSection",
  "/admin/base/section/update",
  types.request.Update
);

export const deleteSection = getResponse(
  "section/deleteSection",
  "/admin/base/section/delete",
  types.request.Delete
);

export const getSectionById = getResponse(
  "section/getSectionById",
  "/admin/base/section/find",
  types.request.Find,
  "section"
);

export const getSections = getResponse(
  "section/getSections",
  "/admin/base/section/list",
  types.request.List,
  "section"
);
