import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Card, Col, Row, Button } from "react-bootstrap";
import {
  deleteStudent,
  formatDateToYYYYMMDD,
  getStudents,
} from "../../../features/students/studentService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import {
  BasicTable,
  Column,
} from "../../../components/tables/DataTables/Basictable";
import BreadcrumbHeader from "../../../components/common/BreadcrumbHeader";
import { resetDelete } from "../studentSlice";

const StudentIndexScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading: deleteLoading, success: deleteSucces } = useAppSelector(
    (state) => state.student.delete
  );
  const { loading, error, students } = useAppSelector(
    (state) => state.student.list
  );

  const handleEdit = (id: any) => {
    navigate(`/students/edit/${id}`);
  };
  const handleAddendance = (id: any) => {
    navigate(`/attendances/students/${id}`);
  };
  //delete useEffect
  useEffect(() => {
    if (deleteSucces) {
      dispatch(resetDelete());
      swalWithBootstrapButtons
        .fire("Deleted!", "Deleted student Successfully.", "success")
        .then((result: any) => {
          if (result.isConfirmed) {
            //navigate("/students/list");
            dispatch(
              getStudents({
                student: {},
              })
            );
          }
        });
    }
  }, [dispatch, deleteSucces, navigate]);

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  const deleteAlert = (id: number) => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "Your will not be able to recover this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(
            deleteStudent({
              data: {
                student: [
                  {
                    student_id: id,
                  },
                ],
              },
            })
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
  };
  const COLUMNS: Column[] = [
    {
      header: "Id",
      accessor: "student_id",
      className: "borderrigth",
    },
    {
      header: "Name",
      accessor: "student_name",
      className: "borderrigth",
    },
    {
      header: "DOB",
      accessor: "student_dob",
      className: "borderrigth",
      Cell: ({ row }: any) => (
        <span className="w-100 text-center">
          {formatDateToYYYYMMDD(new Date(row.original.student_dob))}
        </span>
      ),
    },
    {
      header: "Class",
      accessor: "running_class",
      className: "borderrigth",
      Cell: ({ row }: any) => (
        <span className="w-2o text-center">{row.original.running_class}</span>
      ),
    },
    {
      header: "Parents",
      accessor: "father_name",
      className: "borderrigth",
    },
    {
      header: "Contact Number",
      accessor: "primary_conact_phone",
      className: "borderrigth",
    },
    {
      header: "Address",
      accessor: "student_address",
      className: "borderrigth",
      Cell: ({ row }: any) => (
        <span className="w-100 text-center">
          {row.original.student_address?.length >= 65
            ? `${row.original.student_address.substring(1, 65)}...`
            : row.original.student_address}
        </span>
      ),
    },
    // {
    //   header: "Status",
    //   accessor: "is_active",
    //   className: "wd-15p borderrigth text-center",
    //   Cell: ({ row }: any) => (
    //     <span className="w-100 text-center">
    //       {row.original.is_active === 1 ? "Active" : "Inactive"}
    //     </span>
    //   ),
    // },
    {
      header: "Actions",
      accessor: "id", // Assuming 'id' is a unique identifier for each row
      className: "action-column d-flex flex-row justify-content-center",
      Cell: ({ row }: any) => (
        <span className="text-center">
          <Button
            variant="primary"
            size="sm"
            onClick={() => handleEdit(row.original.student_id)}
          >
            <i className="fa fa-pen"></i>
          </Button>
          <Button
            className="ml-2"
            variant="danger"
            size="sm"
            onClick={() => deleteAlert(row.original.student_id)}
          >
            <i className="fa fa-trash"></i>
          </Button>
          <Button
            className="ml-2"
            variant="warning"
            size="sm"
            onClick={() => handleAddendance(row.original.student_id)}
          >
            <i className="fa fa-clipboard"></i>
          </Button>
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (error != null) {
      toast.error(error.message);
    }
    dispatch(
      getStudents({
        student: {},
      })
    );
  }, [dispatch, error]);

  console.log(students);
  const breadcrumbItems = [
    { text: "Student", href: "#" },
    { text: "All", isActive: true },
  ];
  const rightButton = (
    <Button
      variant="primary"
      className="mb-4 ml-2 btn btn-sm"
      onClick={() => navigate("/students/entry")}
    >
      <span>
        <i className="fa fa-plus"></i>
        <span style={{ marginLeft: "5px !important" }}> New</span>
      </span>
    </Button>
  );
  return (
    <div>
      <BreadcrumbHeader
        mainContentTitle="Student Info"
        breadcrumbItems={breadcrumbItems}
      />
      <div className="main-container container-fluid">
        {/* <!-- Row --> */}
        <Row className=" row-sm">
          <Col lg={12}>
            <Card className="custom-card overflow-hidden">
              <Card.Body>
                <div>
                  <h6 className="main-content-label mb-1">All Students</h6>
                </div>
                <div className="table-responsive">
                  <BasicTable
                    columns={COLUMNS}
                    data={students}
                    RightButton={rightButton}
                    loading={loading}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <!-- End Row --> */}
      </div>
    </div>
  );
};

export default StudentIndexScreen;
