import { createAction } from "@reduxjs/toolkit";
import types from "../common/types";
import { getApiResponse } from "../common/api.service";

export const getStudentAttendanceSummary = (payload: any) => {
  console.log("payload", payload);
  return getApiResponse(
    "/admin/transaction/attendance/student/summary",
    types.request.List,
    "studentAttendances",
    payload
  );
};

export const getStudentAttendanceList = (payload: any) =>
  getApiResponse(
    "/admin/transaction/attendance/student/list",
    types.request.List,
    "studentAttendances",
    payload
  );
export const getAttendanceById = (payload: any) =>
  getApiResponse(
    "/admin/transaction/attendance/student/list",
    types.request.Find,
    "studentAttendances",
    payload
  );
export const setSelected = createAction("SET_SELECTED");

// export const getStudentAttendanceSummary = getResponse(
//   "attendances/getStudentAttendanceSummary",
//   "/admin/transaction/attendance/student/summary",
//   types.request.List,
//   "attendances"
// );

// export const getStudentAttendanceList = getResponse(
//   "attendances/getStudentAttendanceList",
//   "/admin/transaction/attendance/student/list",
//   types.request.List,
//   "student"
// );
