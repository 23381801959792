import { FC, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

import {
  FormCheckBox,
  FormInput,
  FormTextarea,
} from "../../../components/forms";

interface OtherInfoSectionProps {
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
}

const OtherInfoSection: FC<OtherInfoSectionProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  //Fileuploader
  const [Fileuploader, setFileuploader] = useState([]);
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setFileuploader(files);
  }
  return (
    <section className="card-body Basicwizard ">
      <div className="main-content-label mg-b-5">Other Info</div>
      <div className="pd-30 pd-sm-20">
        <Row className="row-xs align-items-center mg-b-5">
          <Col md={4}>
            <Dropzone
              onDrop={(acceptedFiles) => {
                handleAcceptedFiles(acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone dz-clickable">
                  <div className="dz-message needsclick" {...getRootProps()}>
                    <div className="mb-2 mt-5 dropzoneicon ">
                      <i className="mdi mdi-apple-mobileme"></i>
                    </div>
                    <p style={{ color: "#9393b5" }}>
                      Drop files here or click to upload.
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
            <div className="list-unstyled mb-0" id="file-previews">
              {Fileuploader.map((f: any, i: any) => {
                return (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={f.name}
                            src={f.preview}
                          />
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted font-weight-bold">
                            {f.name}
                          </Link>
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                );
              })}
            </div>
          </Col>
          <Col md={8} className=" mg-t-5 mg-md-t-0">
            <FormTextarea
              label="*Note"
              name="student_note"
              values={values}
              errors={errors}
              row={3}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              placeholder="Enter note"
            />
          </Col>
        </Row>
        <Row className="row-xs align-items-center mg-b-5">
          <Col md={4}>
            <FormCheckBox
              label="Active?"
              name="is_active"
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </Col>
          <FormInput
            label="RFID Number"
            name="student_rfid_number"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter RFID number"
            input_md={3}
            label_md={3}
          />
        </Row>
        <Row className="row-xs align-items-center mg-b-5">
          <Col md={4}>
            <FormCheckBox
              label="Dormant?"
              name="is_dorment"
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </Col>
          <FormInput
            label="Authorization Code"
            name="registration_authorization_code"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter Authorization Code"
            input_md={3}
            label_md={3}
          />
        </Row>
        <Row>
          <Col md={8}></Col>
          <Col md={3} className=" mg-t-5 mg-md-t-0">
            <Button
              type="submit"
              className="btn btn-primary pd-x-30 mg-r-5 mg-t-5"
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default OtherInfoSection;
