import { FC, useEffect } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Formik, Form as FormikForm, FormikHelpers } from "formik";
import * as Yup from "yup";
import BreadcrumbHeader from "../../components/common/BreadcrumbHeader";

import {
  FormInput,
  FormSelect,
  FormTextarea,
  FormCheckBox,
} from "../../components/forms";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  SectionPayload,
  addSection,
} from "../../features/sections/sectionService";
import { ToastContainer, toast } from "react-toastify";
import { resetSave } from "../../features/sections/sectionSlice";
import {
  getLovClass,
  getLovBranch,
  getLovShift,
  getLovTeacher,
} from "../../features/lov/lovService";

interface Values {
  section_name: string;
  branch_id: string;
  class_id: string;
  shift_id: string;
  // teacher_id: string;
  hr_id: string;
  section_room_number: string;
  // section_order: string;
  section_description: string;
  is_active: boolean;
}

const initialValues: Values = {
  section_name: "",
  branch_id: "",
  class_id: "",
  shift_id: "",
  hr_id: "",
  // teacher_id: "",
  section_room_number: "",
  // section_order: "",
  section_description: "",
  is_active: true,
};
const validationSchema = Yup.object({
  section_name: Yup.string().required("Required"),
  branch_id: Yup.string().required("Required"),
  class_id: Yup.string().required("Required"),
  shift_id: Yup.string().required("Required"),
  hr_id: Yup.string().required("Required"),
  // teacher_id: Yup.string().required("Required"),
  section_room_number: Yup.string().required("Required"),
  section_description: Yup.string().nullable(),
  is_active: Yup.boolean().required("Required"),
});

const SectionEntryScreen: FC = () => {
  const dispatch = useAppDispatch();
  const { classList, branchList, shiftList, teacherList } = useAppSelector(
    (state) => state.lov
  );
  const { loading: sectionSaveLoading } = useAppSelector(
    (state) => state.section.save
  );
  const breadcrumbItems = [
    { text: "Section", href: "/sections/list" },
    { text: "Entry", isActive: true },
  ];

  useEffect(() => {
    if (classList.classes.length === 0) {
      dispatch(getLovClass({}));
    }
    if (shiftList.shifts.length === 0) {
      dispatch(getLovShift({}));
    }
    if (branchList.branches.length === 0) {
      dispatch(getLovBranch({}));
    }
    if (teacherList.teachers.length === 0) {
      dispatch(getLovTeacher({}));
    }
  }, [dispatch]);
  const handleSubmit = (
    values: Values,
    { resetForm }: FormikHelpers<Values>
  ) => {
    const payload: SectionPayload = {
      data: {
        section: [
          {
            section_name: values.section_name,
            branch_id: values.branch_id,
            class_id: values.class_id,
            shift_id: values.shift_id,
            hr_id: values.hr_id,
            // teacher_id: "",
            section_room_number: values.section_room_number,
            // section_order: "",
            section_description: values.section_description,
            is_active: values.is_active ? 1 : 0,
          },
        ],
      },
    };
    dispatch(addSection(payload))
      // .unwrap()
      .then(() => {
        resetForm();
        toast("Successful!");
        dispatch(resetSave());
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  return (
    <div>
      <ToastContainer />
      <BreadcrumbHeader
        mainContentTitle="Section Info"
        breadcrumbItems={breadcrumbItems}
      />
      <Col lg={6} md={6}>
        <Card>
          <Card.Body>
            <div className="main-content-label mg-b-5">Section Entry</div>
            <div className="pd-30 pd-sm-20">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <FormikForm noValidate onSubmit={handleSubmit}>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormInput
                        label="Section Name"
                        name="section_name"
                        type="text"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        placeholder="Enter section name"
                      />
                    </Row>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormSelect
                        label="Branch"
                        name="branch_id"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        options={branchList.branches.map((item: any) => {
                          return {
                            value: item.branch_id,
                            label: item.branch_name,
                          };
                        })}
                        placeholder="Select branch"
                      />
                    </Row>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormSelect
                        label="Class"
                        name="class_id"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        options={classList.classes.map((item: any) => {
                          return {
                            value: item.class_id,
                            label: item.class_name,
                          };
                        })}
                        loading={classList.loading}
                      />
                    </Row>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormSelect
                        label="Shift"
                        name="shift_id"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        options={shiftList.shifts.map((item: any) => {
                          return {
                            value: item.shift_id,
                            label: item.shift_name,
                          };
                        })}
                        loading={classList.loading}
                      />
                    </Row>
                    {/* <Row className="row-xs align-items-center mg-b-5">
                      <FormSelect
                        label="Teacher"
                        name="teacher_id"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        options={teacherOptions}
                        placeholder="Select teacher"
                      />
                    </Row> */}
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormSelect
                        label="HR"
                        name="hr_id"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        options={teacherList.teachers.map((item: any) => {
                          return {
                            value: item.teacher_id,
                            label: item.teacher_name,
                          };
                        })}
                        loading={classList.loading}
                        placeholder="Select teacher"
                      />
                    </Row>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormInput
                        label="Room Number"
                        name="section_room_number"
                        type="number"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        placeholder="Enter room number"
                      />
                    </Row>
                    {/* <Row className="row-xs align-items-center mg-b-5">
                      <FormSelect
                        label="Section Order"
                        name="section_order"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        options={sectionOrderOptions}
                        placeholder="Select section order"
                      />
                    </Row> */}
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormTextarea
                        label="Description"
                        name="section_description"
                        values={values}
                        errors={errors}
                        row={2}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        placeholder="Enter description"
                      />
                    </Row>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormCheckBox
                        label="Active"
                        name="is_active"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    </Row>
                    <Row>
                      <Col md={3}></Col>
                      <Col md={9} className=" mg-t-5 mg-md-t-0 pull-right">
                        <Button
                          disabled={sectionSaveLoading}
                          type="submit"
                          className="btn btn-primary pd-x-30 mg-r-5 mg-t-5"
                        >
                          {sectionSaveLoading ? "Saving..." : "Save"}
                        </Button>
                      </Col>
                    </Row>
                  </FormikForm>
                )}
              </Formik>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default SectionEntryScreen;
