import { FC, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { getStudentAttendanceList } from "../studentAttendanceService";
import { CardSkeleton } from "../../../components/common/skeletons/CardSkeleton";
import { formatDateToYYYYMMDD } from "../../students/studentService";
interface Props {
  id: any;
  current_date: Date;
}
const TodaysActivityCard: FC<Props> = ({ id, current_date }) => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log(current_date);
  //alert(formatDateToYYYYMMDD(current_date));
  useEffect(() => {
    getStudentAttendanceList({
      attendance: {
        student_id: id, //optional id
        date_log: formatDateToYYYYMMDD(current_date), //optional formatDateToYYYYMMDD(current_date)
      },
    })
      .then((data) => {
        setActivities(data.result?.attendance);
        setLoading(false);
        console.log(data.result?.attendance);
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
        setActivities([]);
        setLoading(false);
      });
  }, [current_date]);
  return (
    <Card style={{ height: "97%" }} className="custom-card">
      <Card.Header className=" custom-card-header">
        <h6 className="card-title mb-0">Todays Activity</h6>
      </Card.Header>
      <Card.Body className="">
        {loading ? (
          <CardSkeleton skeletonNumberOfRows={5} />
        ) : activities.length !== 0 ? (
          <div className="vtimeline">
            {activities.map((activity: any, index) => (
              <div
                key={index}
                className={`timeline-wrapper ${
                  index % 2 === 0 ? "" : "timeline-inverted"
                } timeline-wrapper-primary`}
              >
                <div
                  className="timeline-badge success"
                  style={{
                    width: 30,
                    height: 30,
                    left: "calc(50% - 15px)",
                  }}
                ></div>
                <div className="timeline-panel p-1">
                  <div className="timeline-heading">
                    <h6 className="timeline-title mb-0">{activity.time_log}</h6>
                  </div>
                  <div className="timeline-body">
                    <p style={{ marginBottom: 0 }}>
                      {index % 2 === 0 ? "Punch In" : "Punch Out"}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No Data found</p>
        )}
      </Card.Body>
    </Card>
  );
};

export default TodaysActivityCard;
