import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";

interface BreadcrumbHeaderProps {
  mainContentTitle: string;
  breadcrumbItems: { text: string; href?: string; isActive?: boolean }[];
}

const BreadcrumbHeader: React.FC<BreadcrumbHeaderProps> = ({
  mainContentTitle,
  breadcrumbItems,
}) => {
  const navigate = useNavigate();
  return (
    <div className="breadcrumb-header justify-content-between">
      <div className="left-content">
        <span className="main-content-title mg-b-0 mg-b-lg-1">
          {mainContentTitle}
        </span>
      </div>
      <div className="justify-content-center mt-2">
        <Breadcrumb className="breadcrumb">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item
              key={index}
              className={`breadcrumb-item tx-15 ${
                item.isActive ? "active" : ""
              }`}
              // href={item.href}
              onClick={() => navigate(item.href || "")}
              active={item.isActive}
              aria-current={item.isActive ? "page" : undefined}
            >
              {item.text}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
    </div>
  );
};

export default BreadcrumbHeader;
