import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  Section,
  addSection,
  deleteSection,
  getSections,
  getSectionById,
  updateSection,
} from "./sectionService";

interface SectionState {
  list: {
    loading: boolean;
    error: any | null;
    sections: Section[] | [];
  };
  save: {
    loading: boolean;
    success: boolean;
    error: any | null;
    section: Section | null;
  };
  delete: {
    loading: boolean;
    success: boolean;
    error: any | null;
  };
}
const initialState = {
  list: {
    loading: false,
    success: false,
    error: null,
    sections: [],
  },
  save: {
    loading: false,
    success: false,
    error: null,
    section: null,
  },
  delete: {
    loading: false,
    success: false,
    error: null,
  },
} as SectionState;

const sectionSlice = createSlice({
  name: "sections",
  initialState,
  reducers: {
    resetList: (state) => {
      state.list.loading = false;
      state.list.error = null;
      state.list.sections = [];
    },
    resetSave: (state) => {
      state.save.loading = false;
      state.save.success = false;
      state.save.error = null;
      state.save.section = null;
    },
    resetDelete: (state) => {
      state.delete.loading = false;
      state.delete.success = false;
      state.delete.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSections.pending, (state, action) => {
        state.list.loading = true;
      })
      .addCase(
        getSections.fulfilled,
        (state, action: PayloadAction<Section[]>) => {
          state.list.loading = false;
          state.list.error = null;
          state.list.sections = action.payload;
        }
      )
      .addCase(getSections.rejected, (state, action: PayloadAction<any>) => {
        state.list.loading = false;
        state.list.sections = [];
        state.list.error = action.payload;
      })
      .addCase(addSection.pending, (state, action) => {
        state.save.loading = true;
      })
      .addCase(addSection.fulfilled, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = true;
        state.save.error = null;
        state.save.section = action.payload;
      })
      .addCase(addSection.rejected, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = true;
        state.save.error = action.payload;
        state.save.section = null;
      })
      .addCase(getSectionById.pending, (state, action) => {
        state.save.loading = true;
      })
      .addCase(
        getSectionById.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.save.loading = false;
          state.save.error = null;
          state.save.section = action.payload;
        }
      )
      .addCase(getSectionById.rejected, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.error = action.payload;
        state.save.section = null;
      })
      .addCase(deleteSection.pending, (state, action) => {
        state.delete.loading = true;
      })
      .addCase(deleteSection.fulfilled, (state, action: PayloadAction<any>) => {
        state.delete.loading = false;
        state.delete.success = true;
        state.delete.error = null;
      })
      .addCase(deleteSection.rejected, (state, action: PayloadAction<any>) => {
        state.delete.loading = false;
        state.delete.success = false;
        state.delete.error = action.payload;
      })
      .addCase(updateSection.pending, (state, action) => {
        state.save.loading = true;
      })
      .addCase(updateSection.fulfilled, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = true;
        state.save.error = null;
        state.save.section = action.payload;
      })
      .addCase(updateSection.rejected, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = false;
        state.save.error = action.payload;
        state.save.section = null;
      });
  },
});

export const selectCount = (state: RootState) => state.section;

export const { resetList, resetSave, resetDelete } = sectionSlice.actions;

export const sectionReducer = sectionSlice.reducer;
