import { getResponse } from "../common/api.service";
import types from "../common/types";

export interface Shift {
  shift_id: number;
  shift_name: string;
  school_id: number;
  shift_start_time: string;
  shift_end_time: string;
  shift_description: string;
  create_date: string;
  create_by: number;
  update_date: string;
  update_by: number;
  is_active: number;
}

export interface ShiftPayload {
  data: {
    shift: any;
  };
}

export const addShift = getResponse(
  "shift/addShift",
  "/admin/base/shift/add",
  types.request.Update
);

export const updateShift = getResponse(
  "shift/updateShift",
  "/admin/base/shift/update",
  types.request.Update
);

export const deleteShift = getResponse(
  "shift/deleteShift",
  "/admin/base/shift/delete",
  types.request.Delete
);

export const getShiftById = getResponse(
  "shift/getShiftById",
  "/admin/base/shift/find",
  types.request.Find,
  "shift"
);

export const getShifts = getResponse(
  "shift/getShifts",
  "/admin/base/shift/list",
  types.request.List,
  "shift"
);

export const formatTime = (date: Date) => {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  console.log(`${hours}:${minutes}:${seconds}`);

  return `${hours}:${minutes}:${seconds}`;
};

export const parseTimeString = (timeString: string) => {
  if (!timeString) {
    return new Date();
  }
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  return date;
};
