import { useEffect } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

import {
  BasicTable,
  Column,
} from "../../../components/tables/DataTables/Basictable";
import BreadcrumbHeader from "../../../components/common/BreadcrumbHeader";
import { deleteLeave, getLeaves } from "../studentLeaveService";
import { formatDateToYYYYMMDD } from "../../students/studentService";

const LeaveIndexScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { leaves, loading, error } = useAppSelector(
    (state) => state.student_leaves.list
  );

  useEffect(() => {
    if (error != null) {
      toast.error(error.message);
    }
    dispatch(
      getLeaves({
        leave: {},
      })
    );
  }, [dispatch]);
  console.log(leaves);

  const COLUMNS: Column[] = [
    {
      header: "Student Name",
      accessor: "student_name",
    },
    {
      header: "Student Code",
      accessor: "student_code",
    },
    {
      header: "From Date",
      accessor: "leave_from_date",
      Cell: ({ row }: any) => (
        <>{formatDateToYYYYMMDD(new Date(row.original.leave_from_date))}</>
      ),
    },
    {
      header: "To Date",
      accessor: "leave_to_date",
      Cell: ({ row }: any) => (
        <>{formatDateToYYYYMMDD(new Date(row.original.leave_to_date))}</>
      ),
    },
    {
      header: "Reason",
      accessor: "leave_reason",
    },
    {
      header: "Duration",
      accessor: "leave_duration",
    },
    {
      header: "Actions",
      accessor: "id",
      className: "action-column d-flex flex-row justify-content-center",
      Cell: ({ row }: any) => (
        <span className="w-100 text-center">
          <Button
            variant="danger"
            size="sm"
            onClick={() => deleteAlert(row.original.leave_id)}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </span>
      ),
    },
  ];
  const breadcrumbItems = [
    { text: "Leave", href: "#" },
    { text: "All", isActive: true },
  ];
  const rightButton = (
    <Button
      variant="primary"
      className="mb-4 ml-2 btn btn-sm"
      onClick={() => navigate("/students/leaves/entry")}
    >
      <span>
        <i className="fa fa-plus"></i>
        <span style={{ marginLeft: "5px !important" }}> New</span>
      </span>
    </Button>
  );
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  const deleteAlert = (id: any) => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "Your will not be able to recover this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(
            deleteLeave({
              data: {
                leave: [
                  {
                    leave_id: id,
                  },
                ],
              },
            })
          ).then(() => {
            toast.success("Leave deleted successfully");
            dispatch(
              getLeaves({
                leave: {},
              })
            );
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancel");
        }
      });
  };
  return (
    <div>
      <ToastContainer />
      <BreadcrumbHeader
        mainContentTitle="Leave Info"
        breadcrumbItems={breadcrumbItems}
      />
      <div className="main-container container-fluid">
        {/* <!-- Row --> */}
        <Row className=" row-sm">
          <Col lg={12}>
            <Card className="custom-card overflow-hidden">
              <Card.Body>
                <div>
                  <h6 className="main-content-label mb-1">All Leaves</h6>
                </div>
                <div className="table-responsive">
                  <BasicTable
                    columns={COLUMNS}
                    data={leaves}
                    RightButton={rightButton}
                    loading={loading}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <!-- End Row --> */}
      </div>
    </div>
  );
};

export default LeaveIndexScreen;
