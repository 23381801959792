import { FC, useEffect } from "react";
import { Row } from "react-bootstrap";
import {
  getLovBranch,
  getLovClass,
  getLovSection,
  getLovSession,
} from "../../lov/lovService";

import { FormInput, FormSelect } from "../../../components/forms";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

interface AcademicInfoSectionProps {
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
}
const AcademicInfoSection: FC<AcademicInfoSectionProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  const dispatch = useAppDispatch();
  const { classList, branchList, sectionList, sessionList } = useAppSelector(
    (state) => state.lov
  );
  //const {delete} = useAppSelector((state) => state.student);
  useEffect(() => {
    if (classList.classes.length === 0) {
      dispatch(getLovClass({}));
    }
    if (branchList.branches.length === 0) {
      dispatch(getLovBranch({}));
    }
    if (sectionList.sections.length === 0) {
      dispatch(getLovSection({}));
    }
    if (sessionList.sessions.length === 0) {
      dispatch(getLovSession({}));
    }
  }, [dispatch]);
  return (
    <section className="card-body Basicwizard ">
      <div className="main-content-label mg-b-5">Academic</div>
      <div className="pd-30 pd-sm-20">
        <Row className="row-xs align-items-center mg-b-5">
          <FormSelect
            label="*Branch"
            name="branch_id"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            loading={branchList.loading}
            options={branchList.branches.map((item: any) => {
              return {
                value: item.branch_id,
                label: item.branch_name,
              };
            })}
            placeholder="Select class"
          />
        </Row>
        <Row className="row-xs align-items-center mg-b-5">
          <FormSelect
            label="*Class"
            name="running_class"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            loading={classList.loading}
            options={classList.classes.map((item: any) => {
              return {
                value: item.class_id,
                label: item.class_name,
              };
            })}
            placeholder="Select class"
          />
        </Row>
        {/* Section Select */}
        <Row className="row-xs align-items-center mg-b-5">
          <FormSelect
            label="*Section"
            name="running_section"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            loading={sectionList.loading}
            options={sectionList.sections.map((item: any) => {
              return {
                value: item.section_id,
                label: item.section_name,
              };
            })}
            placeholder="Select class"
          />
        </Row>
        {/* Session Select */}
        <Row className="row-xs align-items-center mg-b-5">
          <FormSelect
            label="*Session"
            name="running_session"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            loading={sessionList.loading}
            options={sessionList.sessions.map((item: any) => {
              return {
                value: item.session_id,
                label: item.session_name,
              };
            })}
            placeholder="Select session"
          />
        </Row>
        {/* Roll No text */}
        <Row className="row-xs align-items-center mg-b-5">
          <FormInput
            label="*Roll number"
            name="running_roll_number"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter roll number"
          />
        </Row>
      </div>
    </section>
  );
};

export default AcademicInfoSection;
