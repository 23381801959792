import React from "react";
function getClsNavBtn(active: any) {
  return "btn" + (active ? " active" : "");
}
function Button({ visible, ...props }: any) {
  return (
    <button
      type="button"
      className={visible ? "btn btn-primary " : "invisible"}
      {...props}
    />
  );
}
//Basic start
const Wizard = ({ step: currentIndex, ...props }: any) => {
  const steps: any = React.Children.toArray(props.children);
  const prevStep: any = currentIndex !== 0 && steps[currentIndex - 1].props;
  const nextStep: any =
    currentIndex !== steps.length - 1 && steps[currentIndex + 1].props;

  return (
    <div>
      <nav className="btn-group steps basicsteps">
        {steps.map((step: any, index: any) => (
          <Button
            type="button"
            key={step.props.number}
            onClick={() => props.onChange(index)}
            className={getClsNavBtn(index === currentIndex)}
          >
            <span className="number me-2 ">{step.props.number}</span>
            <i
              className={index === currentIndex ? "text-primary" : "text-default"}
            >
              {step.props.title}
            </i>
          </Button>
        ))}
      </nav>

      {steps[currentIndex]}

      <div className=" p-3 d-flex justify-content-between  ">
        <Button
          type="button"
          visible={prevStep}
          onClick={() => props.onChange(currentIndex - 1)}
          title={prevStep.description}
        >
          Back
        </Button>
        <Button
          type="button"
          visible={nextStep}
          onClick={() => props.onChange(currentIndex + 1)}
          title={nextStep.description}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
export default Wizard;
