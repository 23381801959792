import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  addClass,
  ClassData,
  deleteClass,
  getClass,
  getClassById,
  updateClass,
} from "./classService";

interface ClassState {
  list: {
    loading: boolean;
    success: boolean;
    error: any | null;
    classes: ClassData[] | [];
  };
  save: {
    loading: boolean;
    success: boolean;
    error: any | null;
    classInfo: ClassData | null;
  };
  delete: {
    loading: boolean;
    success: boolean;
    error: any | null;
  };
}
const initialState = {
  list: {
    loading: false,
    success: false,
    error: null,
    classes: [],
  },
  save: {
    loading: false,
    success: false,
    error: null,
    classInfo: null,
  },
  delete: {
    loading: false,
    success: false,
    error: null,
  },
} as ClassState;

const classSlice = createSlice({
  name: "classes",
  initialState,
  reducers: {
    resetList: (state) => {
      state.list.loading = false;
      state.list.success = false;
      state.list.error = null;
      state.list.classes = [];
    },
    resetSave: (state) => {
      state.save.loading = false;
      state.save.success = false;
      state.save.error = null;
      state.save.classInfo = null;
    },
    resetDelete: (state) => {
      state.delete.loading = false;
      state.delete.success = false;
      state.delete.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClass.pending, (state, action) => {
        state.list.loading = true;
      })
      .addCase(
        getClass.fulfilled,
        (state, action: PayloadAction<ClassData[]>) => {
          state.list.loading = false;
          state.list.success = true;
          state.list.error = null;
          state.list.classes = action.payload;
        }
      )
      .addCase(getClass.rejected, (state, action: PayloadAction<any>) => {
        state.list.loading = false;
        state.list.success = true;
        state.list.classes = [];
        state.list.error = action.payload;
      })
      .addCase(addClass.pending, (state, action) => {
        state.save.loading = true;
      })
      .addCase(addClass.fulfilled, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = true;
        state.save.error = null;
        state.save.classInfo = action.payload;
      })
      .addCase(addClass.rejected, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = true;
        state.save.error = action.payload;
        state.save.classInfo = null;
      })
      .addCase(getClassById.pending, (state, action) => {
        state.save.loading = true;
      })
      .addCase(getClassById.fulfilled, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.error = null;
        state.save.classInfo = action.payload;
      })
      .addCase(getClassById.rejected, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.error = action.payload;
        state.save.classInfo = null;
      })
      .addCase(deleteClass.pending, (state, action) => {
        state.delete.loading = true;
      })
      .addCase(deleteClass.fulfilled, (state, action: PayloadAction<any>) => {
        state.delete.loading = false;
        state.delete.success = true;
        state.delete.error = null;
      })
      .addCase(deleteClass.rejected, (state, action: PayloadAction<any>) => {
        state.delete.loading = false;
        state.delete.success = false;
        state.delete.error = action.payload;
      })
      .addCase(updateClass.pending, (state, action) => {
        state.save.loading = true;
      })
      .addCase(updateClass.fulfilled, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = true;
        state.save.error = null;
        state.save.classInfo = action.payload;
      })
      .addCase(updateClass.rejected, (state, action: PayloadAction<any>) => {
        state.save.loading = false;
        state.save.success = false;
        state.save.error = action.payload;
        state.save.classInfo = null;
      });
  },
});

export const selectCount = (state: RootState) => state.class;

export const { resetList, resetSave, resetDelete } = classSlice.actions;

export const classReducer = classSlice.reducer;
