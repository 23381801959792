import reportWebVitals from "./reportWebVitals";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Auth from "./pages/auth/auth";
import "./index.scss";
import Loader from "./components/shade/Loaders/Loaders";
import { Provider } from "react-redux";
import { store } from "./app/store";

import TeacherAttendanceIndexScreen from "./features/teacherAttendances/pages/TeacherAttendanceIndexScreen";
import StudentAttendanceIndexScreen from "./features/studentAttendances/pages/StudentAttendanceIndexScreen";
import StudentIndexScreen from "./features/students/pages/StudentIndexScreen";
import StudentEntryScreen from "./features/students/pages/StudentEntryScreen";
import StudentEditScreen from "./features/students/pages/StudentEditScreen";
import ClassIndexScreen from "./pages/classes/ClassIndexScreen";
import ClassEntryScreen from "./pages/classes/ClassEntryScreen";
import ClassEditScreen from "./pages/classes/ClassEditScreen";
import ShiftEntryScreen from "./pages/shifts/ShiftEntryScreen";
import ShiftEditScreen from "./pages/shifts/ShiftEditScreen";
import ShiftIndexScreen from "./pages/shifts/ShiftIndexScreen";
import SectionEntryScreen from "./pages/sections/SectionEntryScreen";
import SectionEditScreen from "./pages/sections/SectionEditScreen";
import SectionIndexScreen from "./pages/sections/SectionIndexScreen";
import AmendmentEntryScreen from "./features/studentAmendments/pages/AmendmentEntryScreen";
import AmendmentIndexScreen from "./features/studentAmendments/pages/AmendmentIndexScreen";
import LeaveIndexScreen from "./features/studentLeaves/pages/LeaveIndexScreen";
import LeaveEntryScreen from "./features/studentLeaves/pages/LeaveEntryScreen";

const App = React.lazy(() => import("../src/components/shade/layouts/App"));

const DashboardScreen = React.lazy(
  () => import("./pages/dashboard/DashboardScreen")
);
const LoginScreen = React.lazy(() => import("./pages/auth/LoginScreen"));
const SignupScreen = React.lazy(() => import("./pages/auth/SignupScreen"));
const Error404 = React.lazy(() => import("./pages/errors/404Error"));

// const Error500 = React.lazy(() => import("./pages/errors/500Error"));
// const Error501 = React.lazy(() => import("./pages/errors/501Error"));

//Form
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>

  // </React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <React.Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Auth />}>
            <Route index element={<LoginScreen />} />
            <Route path="/auth/login" element={<LoginScreen />} />
            <Route path="/auth/signup" element={<SignupScreen />} />
            <Route path="*" element={<Error404 />} />
          </Route>
          <Route path="/" element={<App />}>
            <Route path="/dashboard" element={<DashboardScreen />} />
            {/* Student Screens */}
            <Route path="/students" element={<StudentIndexScreen />} />
            <Route path="/students/entry" element={<StudentEntryScreen />} />
            <Route path="/students/edit/:id" element={<StudentEditScreen />} />
            {/* Class Screens */}
            <Route path="/classes/list" element={<ClassIndexScreen />} />
            <Route path="/classes/entry" element={<ClassEntryScreen />} />
            <Route path="/classes/edit/:id" element={<ClassEditScreen />} />
            {/* Shift Screens */}
            <Route path="/shifts/entry" element={<ShiftEntryScreen />} />
            <Route path="/shifts/edit/:id" element={<ShiftEditScreen />} />
            <Route path="/shifts/list" element={<ShiftIndexScreen />} />
            {/* Section Screens */}
            <Route path="/sections/entry" element={<SectionEntryScreen />} />
            <Route path="/sections/edit/:id" element={<SectionEditScreen />} />
            <Route path="/sections/list" element={<SectionIndexScreen />} />
            {/* Teacher Attendance */}
            <Route
              path="/attendances/teachers"
              element={<TeacherAttendanceIndexScreen />}
            />
            <Route
              path="/attendances/students/:id"
              element={<StudentAttendanceIndexScreen />}
            />
            {/* Amendment */}
            <Route
              path="/students/amendments"
              element={<AmendmentIndexScreen />}
            />
            <Route
              path="/students/amendments/entry"
              element={<AmendmentEntryScreen />}
            />

            {/* Leave */}
            <Route path="/students/leaves" element={<LeaveIndexScreen />} />
            <Route
              path="/students/leaves/entry"
              element={<LeaveEntryScreen />}
            />
          </Route>
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
