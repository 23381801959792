import { FC } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Formik, FormikHelpers, Form as FormikForm } from "formik";
import * as Yup from "yup";
import BreadcrumbHeader from "../../components/common/BreadcrumbHeader";

import {
  FormInput,
  FormTextarea,
  FormCheckBox,
  FormDatepicker,
} from "../../components/forms";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addShift, formatTime } from "../../features/shifts/shiftService";
import { ToastContainer, toast } from "react-toastify";
import { resetSave } from "../../features/shifts/shiftSlice";

interface Values {
  shift_name: string;
  shift_start_time: Date;
  shift_end_time: Date;
  shift_description: string;
  is_active: boolean;
}

const initialValues: Values = {
  shift_start_time: new Date(),
  shift_end_time: new Date(),
  shift_name: "",
  shift_description: "",
  is_active: true,
};
const validationSchema = Yup.object({
  shift_name: Yup.string().required("Required"),
  shift_start_time: Yup.string().required("Required"),
  shift_end_time: Yup.string().required("Required"),
  shift_description: Yup.string().nullable(),
  is_active: Yup.boolean().required("Required"),
});

const ShiftEntryScreen: FC = () => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.shift.save);
  const breadcrumbItems = [
    { text: "Shift", href: "/shifts/list" },
    { text: "Entry", isActive: true },
  ];

  const handleSubmit = (
    values: Values,
    { resetForm }: FormikHelpers<Values>
  ) => {
    const payload = {
      data: {
        shift: [
          {
            shift_name: values.shift_name,
            shift_start_time: formatTime(values.shift_start_time),
            shift_end_time: formatTime(values.shift_end_time),
            shift_description: values.shift_description,
            is_active: values.is_active ? 1 : 0,
          },
        ],
      },
    };
    dispatch(addShift(payload))
      // .unwrap()
      .then(() => {
        resetForm();
        toast("Successful!");
        dispatch(resetSave());
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <div>
      <ToastContainer />
      <BreadcrumbHeader
        mainContentTitle="Shift Info"
        breadcrumbItems={breadcrumbItems}
      />
      <Col lg={6} md={6}>
        <Card>
          <Card.Body>
            <div className="main-content-label mg-b-5">Shift Entry</div>
            <div className="pd-30 pd-sm-20">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <FormikForm noValidate onSubmit={handleSubmit}>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormInput
                        label="Shift Name"
                        name="shift_name"
                        type="text"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        placeholder="Enter shift name"
                      />
                    </Row>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormDatepicker
                        label="Start Time"
                        name="shift_start_time"
                        values={values}
                        errors={errors}
                        touched={touched}
                        // handleChange={handleChange}
                        handleBlur={handleBlur}
                        // initialDate={new Date()}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="hh:mm:ss"
                      />
                    </Row>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormDatepicker
                        label="End Time"
                        name="shift_end_time"
                        values={values}
                        errors={errors}
                        touched={touched}
                        // handleChange={handleChange}
                        handleBlur={handleBlur}
                        // initialDate={new Date()}
                        //dateFormat="h:mm aa"
                        dateFormat="hh:mm:ss"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                      />
                    </Row>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormTextarea
                        label="Description"
                        name="shift_description"
                        values={values}
                        errors={errors}
                        row={2}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        placeholder="Enter description"
                      />
                    </Row>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormCheckBox
                        label="Active"
                        name="is_active"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    </Row>
                    <Row>
                      <Col md={3}></Col>
                      <Col md={9} className=" mg-t-5 mg-md-t-0 pull-right">
                        <Button
                          disabled={loading}
                          type="submit"
                          className="btn btn-primary pd-x-30 mg-r-5 mg-t-5"
                        >
                          {loading ? "Loading..." : "Save"}
                        </Button>
                      </Col>
                    </Row>
                  </FormikForm>
                )}
              </Formik>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default ShiftEntryScreen;
