import { useEffect } from "react";
import { Card, Col, Row, Spinner, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getShifts } from "../../features/shifts/shiftService";

import {
  BasicTable,
  Column,
} from "../../components/tables/DataTables/Basictable";
import BreadcrumbHeader from "../../components/common/BreadcrumbHeader";

const ShiftIndexScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { shifts, loading, error } = useAppSelector(
    (state) => state.shift.list
  );

  useEffect(() => {
    if (error != null) {
      toast.error(error.message);
    }
    dispatch(
      getShifts({
        shift: {},
      })
    );
  }, [dispatch]);

  const handleEdit = (id: any) => {
    console.log(`Edit row with ID: ${id}`);
    navigate(`/shifts/edit/${id}`);
  };
  const COLUMNS: Column[] = [
    {
      header: "Shift Name",
      accessor: "shift_name",
      className: "wd-20p borderrigth",
    },
    {
      header: "Start Time",
      accessor: "shift_start_time",
      className: "wd-25p borderrigth",
    },
    {
      header: "End Time",
      accessor: "shift_end_time",
      className: "wd-25p borderrigth",
    },
    {
      header: "Description",
      accessor: "shift_description",
      className: "wd-20p borderrigth",
    },
    {
      header: "Status",
      accessor: "is_active",
      className: "wd-15p borderrigth text-center",
      Cell: ({ row }: any) => (
        <span className="w-100 text-center">
          {row.original.is_active === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      header: "Actions",
      accessor: "id", // Assuming 'id' is a unique identifier for each row
      className: "action-column d-flex flex-row justify-content-center",
      Cell: ({ row }: any) => (
        <span className="w-100 text-center">
          <Button
            variant="primary"
            size="sm"
            onClick={() => handleEdit(row.original.shift_id)}
          >
            <i className="fa fa-pen"></i>
          </Button>
        </span>
      ),
    },
  ];
  console.log(shifts);
  const breadcrumbItems = [
    { text: "Shifts", href: "#" },
    { text: "All", isActive: true },
  ];
  const rightButton = (
    <Button
      variant="primary"
      className="mb-4 ml-2 btn btn-sm"
      onClick={() => navigate("/shifts/entry")}
    >
      <span>
        <i className="fa fa-plus"></i>
        <span style={{ marginLeft: "5px !important" }}> New</span>
      </span>
    </Button>
  );
  return (
    <div>
      <BreadcrumbHeader
        mainContentTitle="Shift Info"
        breadcrumbItems={breadcrumbItems}
      />
      <div className="main-container container-fluid">
        {/* <!-- Row --> */}
        <Row className=" row-sm">
          <Col lg={12}>
            <Card className="custom-card overflow-hidden">
              <Card.Body>
                <div>
                  <h6 className="main-content-label mb-1">All Shifts</h6>
                </div>
                <div className="table-responsive">
                  <BasicTable
                    columns={COLUMNS}
                    data={shifts}
                    RightButton={rightButton}
                    loading={loading}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <!-- End Row --> */}
      </div>
    </div>
  );
};

export default ShiftIndexScreen;
