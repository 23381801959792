import { FC, Fragment } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ErrorMessage } from "formik";

interface FormCheckBoxProps {
  label: string;
  name: string;
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  label_md?: number;
  input_md?: number;
  handleBlur: any;
  disabled?: boolean;
}

const FormCheckBox: FC<FormCheckBoxProps> = ({
  disabled,
  label,
  name,
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  label_md,
  input_md,
}) => {
  return (
    <Fragment>
      <Col md={label_md ?? 3}></Col>
      <Col md={input_md ?? 9} className="mg-t-5 mg-md-t-0">
        <div className="d-flex align-items-center">
          <Form.Check
            className={`form-label mg-b-0 ${
              errors[name] && touched[name] && "is-invalid"
            }`}
            disabled={disabled ? true : false}
            value={values[name]}
            checked={values[name]}
            onChange={handleChange}
            onBlur={handleBlur}
            id={name}
            name={name}
            type="checkbox"
            label=""
          />
          <Form.Label className="form-label mg-b-0">{label}</Form.Label>
        </div>
        <ErrorMessage name={name} component="div" className="text-danger" />
      </Col>
    </Fragment>
  );
};

export default FormCheckBox;
