import { Formik, FormikHelpers, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { Card, Row, Col } from "react-bootstrap";
import { FC, useEffect } from "react";
import { FormInput, FormDatepicker } from "../../../components/forms";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getStudentById } from "../../students/studentService";
import { CardSkeleton } from "../../../components/common/skeletons/CardSkeleton";

interface Values {
  student_name: string;
  class_name: string;
  section_name: string;
  shift_name: string;
  date_log: any | null;
  punch_in_at: string;
  punch_out_at: string;
}

const validationSchema = Yup.object({
  date_log: Yup.string().required("Required"),
});

interface Props {
  studentId: any;
  currentDate: Date;
  setCurrentDate: any;
}

const StudentAttendanceCard: FC<Props> = ({
  studentId,
  currentDate,
  setCurrentDate,
}) => {
  const dispatch = useAppDispatch();
  const { loading, error, student } = useAppSelector(
    (state) => state.student.save
  );
  //alert(studentId);
  //update useEffect
  useEffect(() => {
    if (error != null) {
      toast.error(error.message);
    } else {
      dispatch(getStudentById({ student: { student_id: studentId } }))
        .unwrap()
        //.then((response) => {})
        .catch((er) => {
          toast.error(er.message);
        });
    }
  }, [dispatch]);

  const handleSubmit = (
    values: Values,
    { resetForm }: FormikHelpers<Values>
  ) => {
    const payload: any = {
      data: {
        class: [
          {
            date_log: values.date_log,
          },
        ],
      },
    };
    // dispatch(addClass(payload))
    //   // .unwrap()
    //   .then(() => {
    //     resetForm();
    //     toast("Successful!");
    //     dispatch(resetSave());
    //   })
    //   .catch((error) => {
    //     toast.error(error.message);
    //   });
  };
  const handleDateChange = (date: any) => {
    // Assuming date is a valid date value, you can update date_log with the selected date.
    //setFieldValue("date_log", date);
    console.log(date);

    setCurrentDate(date);
  };

  return loading ? (
    <CardSkeleton skeletonHeight={35} skeletonNumberOfRows={5} />
  ) : (
    <Card>
      <Card.Header className=" custom-card-header">
        <h6 className="card-title mb-0">Attendance</h6>
      </Card.Header>
      <Card.Body>
        <div className="">
          <Formik
            initialValues={{
              student_name: student?.student_name ?? "",
              class_name: student?.running_class ?? "",
              section_name: student?.running_section ?? "",
              shift_name: student?.running_shift ?? "",
              date_log: currentDate,
              punch_in_at: student?.punch_in_at ?? "",
              punch_out_at: student?.punch_out_at ?? "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            onChange={(e: any) => {
              console.log(e);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <FormikForm noValidate onSubmit={handleSubmit}>
                <Row className="row-xs align-items-center mg-b-5">
                  <FormInput
                    label="Student Name"
                    name="student_name"
                    type="text"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={true}
                    label_md={5}
                    input_md={7}
                    placeholder="Enter class name"
                  />
                </Row>
                <Row className="row-xs align-items-center mg-b-5">
                  <FormInput
                    label="Class"
                    name="class_name"
                    type="text"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={true}
                    label_md={5}
                    input_md={7}
                    placeholder="Enter class name"
                  />
                </Row>
                <Row className="row-xs align-items-center mg-b-5">
                  <FormInput
                    label="Section"
                    name="section_name"
                    type="text"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={true}
                    label_md={5}
                    input_md={7}
                    placeholder="Enter Section name"
                  />
                </Row>
                <Row className="row-xs align-items-center mg-b-5">
                  <FormInput
                    label="Shift"
                    name="shift_name"
                    type="text"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={true}
                    label_md={5}
                    input_md={7}
                    placeholder="Enter Shift"
                  />
                </Row>
                <Row className="row-xs align-items-center mg-b-5">
                  <FormDatepicker
                    label="Date"
                    name="date_log"
                    dateFormat="yyyy-MM-dd"
                    values={values}
                    errors={errors}
                    touched={touched}
                    label_md={5}
                    input_md={7}
                    handleChange={handleDateChange}
                    handleBlur={handleBlur}
                  />
                </Row>
                <Row className="row-xs align-items-center mg-b-5">
                  <FormInput
                    label="Punch in at"
                    name="punch_in_at"
                    type="text"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={true}
                    label_md={5}
                    input_md={7}
                    placeholder="Punch"
                  />
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <Card.Body>
                        <div className="plan-card text-center">
                          <div className="plan-icon">
                            <p style={{ fontSize: "14px !important" }}>3:30</p>
                          </div>
                          <h6 className="text-drak text-uppercase mt-2">
                            Available
                          </h6>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="row-xs align-items-center mg-b-5">
                  <FormInput
                    label="Punch Out at"
                    name="punch_out_at"
                    type="text"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={true}
                    label_md={5}
                    input_md={7}
                    placeholder="Punch"
                  />
                </Row>
                <Row className="row-xs align-items-center mg-b-5">
                  <Col md={6}>Break:30 min</Col>
                  <Col md={6}>Overtime: 20 min</Col>
                </Row>
              </FormikForm>
            )}
          </Formik>
        </div>
      </Card.Body>
    </Card>
  );
};

export default StudentAttendanceCard;
