import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  LoginResponse,
  login,
  logout,
  checkToken,
  darkMode,
} from "./authService";

// Get user from localStorage
const user = localStorage.getItem("user");

interface LoginState {
  loading: boolean;
  success: boolean;
  isLoggedIn: boolean;
  isDarkModeOn: boolean;
  token: string | null;
  error: any | null;
  user: LoginResponse | null;
}
const initialState = {
  loading: false,
  success: false,
  isLoggedIn: user && checkToken() ? true : false,
  isDarkModeOn: localStorage.getItem("darkMode") === "true",
  token: checkToken(),
  error: null,
  user: user ? JSON.parse(user) : null,
} as LoginState;

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.success = false;
      state.isLoggedIn = false;
      state.error = null;
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        login.fulfilled,
        (state, action: PayloadAction<LoginResponse>) => {
          state.loading = false;
          state.isLoggedIn = true;
          state.error = null;
          state.token = action.payload.message;
          state.user = action.payload;
        }
      )
      .addCase(login.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.isLoggedIn = false;
        state.user = null;
        state.token = null;
        state.error = action.payload;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.success = false;
        state.user = null;
        state.token = null;
        state.error = null;
      })
      .addCase(darkMode.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.isDarkModeOn = action.payload;
      });
  },
});

export const selectCount = (state: RootState) => state.auth;

export const { reset } = authSlice.actions;

export const authReducer = authSlice.reducer;
