import { FC, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { getLovCity, getLovState, getLovCountry } from "../../lov/lovService";

import {
  FormCheckBox,
  FormInput,
  FormSelect,
  FormTextarea,
} from "../../../components/forms";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

interface ContactInfoSectionProps {
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
  countryId?: any | null;
}
const ContactInfoSection: FC<ContactInfoSectionProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  countryId,
}) => {
  const dispatch = useAppDispatch();
  const { cityList, stateList, countryList } = useAppSelector(
    (state) => state.lov
  );
  //const {delete} = useAppSelector((state) => state.student);
  useEffect(() => {
    if (countryList.countries.length === 0) {
      dispatch(getLovCountry({}));
    }
    if (countryId) {
      dispatch(
        getLovCity({
          city: {
            list_item_reference_id: countryId, //country_id
          },
        })
      );
      dispatch(
        getLovState({
          state: {
            list_item_reference_id: countryId, //country_id
          },
        })
      );
    }
  }, [dispatch]);
  const handleCountryChange = (country_id: string) => {
    dispatch(
      getLovCity({
        city: {
          list_item_reference_id: country_id, //country_id
        },
      })
    );
    dispatch(
      getLovState({
        state: {
          list_item_reference_id: country_id, //country_id
        },
      })
    );
  };
  return (
    <section className="card-body Basicwizard ">
      <div className="main-content-label mg-b-5">Contact Info</div>
      <div className="pd-30 pd-sm-20">
        <Row className="row-xs align-items-center mg-b-5">
          <FormTextarea
            label="*Address"
            name="student_address"
            values={values}
            errors={errors}
            row={2}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter address"
          />
        </Row>
        <Row className="row-xs align-items-center mg-b-5">
          <FormSelect
            label="*Country"
            name="country_id"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            loading={countryList.loading}
            options={countryList.countries.map((item: any) => {
              return {
                value: item.list_item_id,
                label: item.list_item_name,
              };
            })}
            onCustomChange={handleCountryChange}
            placeholder="Select country"
            label_md={3}
            input_md={3}
          />
          <FormInput
            label="*PO/Postal Code"
            name="student_postal_code"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter mother's email"
            label_md={3}
            input_md={3}
          />
        </Row>
        <Row className="row-xs align-items-center mg-b-5">
          <FormSelect
            label="*State"
            name="state_id"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            loading={stateList.loading}
            options={stateList.states.map((item: any) => {
              return {
                value: item.list_item_id,
                label: item.list_item_name,
              };
            })}
            placeholder="Select state"
            label_md={3}
            input_md={3}
          />
          <FormSelect
            label="*City"
            name="city_id"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            loading={cityList.loading}
            options={cityList.cities.map((item: any) => {
              return {
                value: item.list_item_id,
                label: item.list_item_name,
              };
            })}
            placeholder="Select City"
            label_md={3}
            input_md={3}
          />
        </Row>
        <Row className="row-xs align-items-center mg-b-5">
          <Col md={12}>
            <Form.Label className="form-label mg-b-0">
              *If parents are not primary contact then below information must be
              filled up
            </Form.Label>
          </Col>
        </Row>
        <Row className="row-xs align-items-center mg-b-5">
          <FormInput
            label="*Phone Number"
            name="primary_conact_phone"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter phone number"
          />
        </Row>
        <Row className="row-xs align-items-center mg-b-5">
          <FormInput
            label="*Email"
            name="primary_contact_email"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter phone number"
          />
        </Row>
        <Row className="row-xs align-items-center mg-b-5">
          <FormInput
            label="*Relation"
            name="primary_relation"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter phone number"
          />
        </Row>
      </div>
      {/* Emergency Contact Information */}
      <div className="main-content-label mg-b-5">
        Emergency Contact Information (Not from parent's)
      </div>
      <div className="pd-30 pd-sm-20">
        <Row className="row-xs align-items-center mg-b-5">
          <FormInput
            label="*Name"
            name="emergency_contact_person"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter phone number"
          />
        </Row>
        <Row className="row-xs align-items-center mg-b-5">
          <FormInput
            label="*Relation"
            name="emergency_contact_relation"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter contact relation"
          />
        </Row>
        <Row className="row-xs align-items-center mg-b-5">
          <FormInput
            label="*Phone Number"
            name="emergency_contact_phone"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter contact relation"
            label_md={3}
            input_md={4}
          />
          <Col md={5} className="mg-t-5 mg-md-t-0">
            <FormCheckBox
              label="Use as primary number"
              name="primary_number"
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </Col>
        </Row>
        <Row className="row-xs align-items-center mg-b-5">
          <FormInput
            label="*Email"
            name="emergency_contact_email"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter contact email"
            label_md={3}
            input_md={4}
          />
          <Col md={5} className="mg-t-5 mg-md-t-0">
            <FormCheckBox
              label="Use as primary email"
              name="primary_email"
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </Col>
        </Row>

        {/* Emergency Contact Information End*/}
      </div>
    </section>
  );
};

export default ContactInfoSection;
