import React from "react";
import { Card, Row, Col, ProgressBar } from "react-bootstrap";

const StudentAttendanceStatisticCard = () => {
  return (
    <Card style={{ height: "97%" }}>
      <Card.Header className=" custom-card-header">
        <h6 className="card-title mb-0">Statistics</h6>
      </Card.Header>
      <Card.Body>
        <div>
          <Row>
            <Col md={12}>
              <div className="text-wrap">
                <div className="">
                  <span className="tx-uppercase tx-11 d-block mg-b-5">
                    Today
                  </span>

                  <div className="progress mg-b-10">
                    <ProgressBar
                      className="progress-bar-lg"
                      now={80}
                      variant="success"
                      label="80%"
                    ></ProgressBar>
                  </div>
                </div>
                <div className="">
                  <span className="tx-uppercase tx-11 d-block mg-b-5">
                    This Week Present
                  </span>

                  <div className="progress mg-b-10">
                    <ProgressBar
                      className="progress-bar-lg"
                      now={80}
                      variant="success"
                      label="80%"
                    ></ProgressBar>
                  </div>
                </div>
                <div className="">
                  <span className="tx-uppercase tx-11 d-block mg-b-5">
                    This Month Present
                  </span>

                  <div className="progress mg-b-10">
                    <ProgressBar
                      className="progress-bar-lg"
                      now={60}
                      variant="info"
                      label="80%"
                    ></ProgressBar>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card>
                <Card.Body>
                  <div className="plan-card text-center">
                    <div className="plan-icon">
                      <p style={{ fontSize: "14px !important" }}>02:30</p>
                    </div>
                    <h6 className="text-drak text-uppercase mt-2">Overtime</h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <Card.Body>
                  <div className="plan-card text-center">
                    <div className="plan-icon">
                      <p style={{ fontSize: "14px !important" }}>19/20</p>
                    </div>
                    <h6 className="text-drak text-uppercase mt-2">
                      Remaining Class
                    </h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default StudentAttendanceStatisticCard;
