import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../app/axiosConfig";
import jwt_decode from "jwt-decode";

export type LoginResponse = {
  response_code: string;
  http_code: number;
  message: string;
  result: {
    schoolId: number;
    schoolName: string;
    branchId: number;
    branchName: string;
  };
};
export type LoginPayload = {
  username: string;
  password: string;
};
export const checkToken = () => {
  try {
    let token = localStorage.getItem("token");
    if (!token) return null;
    let decodedToken: any = jwt_decode(token!);
    let currentTime = Date.now() / 1000;
    if (decodedToken.exp < currentTime) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      return null;
    }
    return token;
  } catch (error) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return null;
  }
};
export const login = createAsyncThunk<LoginResponse, LoginPayload>(
  "auth/login",
  async ({ username, password }, thunkApi) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post<LoginResponse>(
        "/admin/auth/login",
        {
          username,
          password,
        },
        config
      );
      //console.log("auth/login", response.data);
      if (response.data.http_code === 200) {
        localStorage.setItem("user", JSON.stringify(response.data.result));
        localStorage.setItem("token", JSON.stringify(response.data.message));
        return response.data;
      }

      return thunkApi.rejectWithValue({
        message: "An unknown error occurred.",
      });
    } catch (error: any) {
      // Handle errors
      //console.log(error.response.data);
      // const message = error.message;
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
});

export const darkMode = createAsyncThunk<boolean, void>(
  "auth/darkMode",
  async () => {
    console.log("darkMode dispatched");
    let darkMode = localStorage.getItem("darkMode");
    if (darkMode === "true") {
      localStorage.setItem("darkMode", "false");
    } else {
      localStorage.setItem("darkMode", "true");
    }
    console.log("darkMode", localStorage.getItem("darkMode"));
    return localStorage.getItem("darkMode") === "true" ? true : false;
  }
);
