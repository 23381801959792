import { FC, useEffect } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Formik, Form as FormikForm, FormikHelpers } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import BreadcrumbHeader from "../../../components/common/BreadcrumbHeader";
import {
  FormSelect,
  FormTextarea,
  FormDatepicker,
} from "../../../components/forms";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { resetSave } from "../../../features/sections/sectionSlice";
import {
  getLovAmendtreat,
  getLovAmendtype,
} from "../../../features/lov/lovService";
import { addAmendment } from "../studentAmendmentService";
import {
  formatDateToYYYYMMDD,
  getStudents,
} from "../../students/studentService";
import { formatTime } from "../../shifts/shiftService";

interface Values {
  student_id: number;
  section_id: number;
  amend_type_id: number;
  amendment_treat_id: number;
  amended_date: Date;
  in_time_log: Date;
  out_time_log: Date;
  amend_reason: string;
}
const initialValues: Values = {
  student_id: 2,
  section_id: 290,
  amend_type_id: 517,
  amendment_treat_id: 518,
  amended_date: new Date(),
  in_time_log: new Date(),
  out_time_log: new Date(),
  amend_reason: "",
};
const validationSchema = Yup.object({
  student_id: Yup.number().required("Required"),
  section_id: Yup.number().required("Required"),
  amend_type_id: Yup.number().required("Required"),
  amendment_treat_id: Yup.number().required("Required"),
  amended_date: Yup.string().required("Required"),
  in_time_log: Yup.string().required("Required"),
  out_time_log: Yup.string().required("Required"),
  amend_reason: Yup.string().required("Required"),
});

const AmendmentEntryScreen: FC = () => {
  const dispatch = useAppDispatch();
  const { amendtypeList, amendtreatList } = useAppSelector(
    (state) => state.lov
  );
  const { loading: studentLoading, students } = useAppSelector(
    (state) => state.student.list
  );
  const { loading: sectionSaveLoading } = useAppSelector(
    (state) => state.section.save
  );
  const breadcrumbItems = [
    { text: "Amendment", href: "/students/amendments" },
    { text: "Entry", isActive: true },
  ];

  useEffect(() => {
    if (amendtypeList.ammendTypes.length === 0) {
      dispatch(getLovAmendtype({}));
    }
    if (amendtreatList.amendtreats.length === 0) {
      dispatch(getLovAmendtreat({}));
    }

    if (students.length === 0) {
      dispatch(
        getStudents({
          student: {},
        })
      );
    }
  }, [dispatch]);

  const handleSubmit = (
    values: Values,
    { resetForm }: FormikHelpers<Values>
  ) => {
    const payload: any = {
      data: {
        amendment: [
          {
            student_id: values.student_id,
            section_id:
              students.find(
                (item: any) => item.student_id === values.student_id
              )?.running_section || 0,
            amend_type_id: values.amend_type_id,
            amendment_treat_id: values.amendment_treat_id,
            amended_date: formatDateToYYYYMMDD(values.amended_date),
            in_time_log: formatTime(values.in_time_log),
            out_time_log: formatTime(values.out_time_log),
            amend_reason: values.amend_reason,
          },
        ],
      },
    };
    dispatch(addAmendment(payload))
      // .unwrap()
      .then(() => {
        resetForm();
        toast("Successful!");
        dispatch(resetSave());
      })
      .catch((error) => {
        toast.error(error.message);
      });
    resetForm();
  };
  return (
    <div>
      <ToastContainer />
      <BreadcrumbHeader
        mainContentTitle="Amendment Info"
        breadcrumbItems={breadcrumbItems}
      />
      <Col lg={6} md={6}>
        <Card>
          <Card.Body>
            <div className="main-content-label mg-b-5">Amendment Entry</div>
            <div className="pd-30 pd-sm-20">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <FormikForm noValidate onSubmit={handleSubmit}>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormSelect
                        label="Student"
                        name="student_id"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        loading={studentLoading}
                        options={students.map((item: any) => {
                          return {
                            value: item.student_id,
                            label: item.student_name,
                          };
                        })}
                        placeholder="Select Student"
                      />
                    </Row>

                    <Row className="row-xs align-items-center mg-b-5">
                      <FormSelect
                        label="Treat"
                        name="amendment_treat_id"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        loading={amendtreatList.loading}
                        options={amendtreatList.amendtreats.map((item: any) => {
                          return {
                            value: item.list_item_id,
                            label: item.list_item_name,
                          };
                        })}
                        placeholder="Select Amendmend Treat"
                      />
                    </Row>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormSelect
                        label="Type"
                        name="amend_type_id"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        loading={amendtypeList.loading}
                        options={amendtypeList.ammendTypes.map((item: any) => {
                          return {
                            value: item.list_item_id,
                            label: item.list_item_name,
                          };
                        })}
                        placeholder="Select Amendmend Treat"
                      />
                    </Row>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormDatepicker
                        label="Date"
                        name="amended_date"
                        dateFormat="yyyy-mm-dd"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={null}
                        handleBlur={handleBlur}
                      />
                    </Row>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormDatepicker
                        label="In Time"
                        name="in_time_log"
                        values={values}
                        errors={errors}
                        touched={touched}
                        // handleChange={handleChange}
                        handleBlur={handleBlur}
                        // initialDate={new Date()}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="hh:mm:ss"
                      />
                    </Row>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormDatepicker
                        label="Out Time"
                        name="out_time_log"
                        values={values}
                        errors={errors}
                        touched={touched}
                        // handleChange={handleChange}
                        handleBlur={handleBlur}
                        // initialDate={new Date()}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="hh:mm:ss"
                      />
                    </Row>
                    <Row className="row-xs align-items-center mg-b-5">
                      <FormTextarea
                        label="Reason"
                        name="amend_reason"
                        values={values}
                        errors={errors}
                        row={2}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        placeholder="Enter reason"
                      />
                    </Row>
                    <Row>
                      <Col md={3}></Col>
                      <Col md={9} className=" mg-t-5 mg-md-t-0 pull-right">
                        <Button
                          disabled={sectionSaveLoading}
                          type="submit"
                          className="btn btn-primary pd-x-30 mg-r-5 mg-t-5"
                        >
                          {sectionSaveLoading ? "Saving..." : "Save"}
                        </Button>
                      </Col>
                    </Row>
                  </FormikForm>
                )}
              </Formik>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default AmendmentEntryScreen;
