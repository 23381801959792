import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  getLovClass,
  getLovSection,
  getLovBranch,
  getLovBloodgroup,
  getLovGender,
  getLovReligion,
  getLovSession,
  getLovShift,
  getLovTeacher,
  getLovCity,
  getLovCountry,
  getLovState,
  getLovNationality,
  getLovAmendtreat,
  getLovAmendtype,
  getLovDaytype,
  getLovEdumedium,
} from "./lovService";

interface LovState {
  classList: {
    loading: boolean;
    success: boolean;
    error: any | null;
    classes: [];
  };
  sectionList: {
    loading: boolean;
    success: boolean;
    error: any | null;
    sections: [];
  };
  branchList: {
    loading: boolean;
    success: boolean;
    error: any | null;
    branches: [];
  };
  shiftList: {
    loading: boolean;
    success: boolean;
    error: any | null;
    shifts: [];
  };
  teacherList: {
    loading: boolean;
    success: boolean;
    error: any | null;
    teachers: [];
  };
  sessionList: {
    loading: boolean;
    success: boolean;
    error: any | null;
    sessions: [];
  };
  bloodGroupList: {
    loading: boolean;
    success: boolean;
    error: any | null;
    bloodGroups: [];
  };
  religionList: {
    loading: boolean;
    success: boolean;
    error: any | null;
    religions: [];
  };
  genderList: {
    loading: boolean;
    success: boolean;
    error: any | null;
    genders: [];
  };
  countryList: {
    loading: boolean;
    success: boolean;
    error: any | null;
    countries: [];
  };
  stateList: {
    loading: boolean;
    success: boolean;
    error: any | null;
    states: [];
  };
  cityList: {
    loading: boolean;
    success: boolean;
    error: any | null;
    cities: [];
  };
  nationalityList: {
    loading: boolean;
    success: boolean;
    error: any | null;
    nationalities: [];
  };
  amendtypeList: {
    loading: boolean;
    success: boolean;
    error: any | null;
    ammendTypes: [];
  };
  amendtreatList: {
    loading: boolean;
    success: boolean;
    error: any | null;
    amendtreats: [];
  };
  daytypeList: {
    loading: boolean;
    success: boolean;
    error: any | null;
    daytypes: [];
  };
  edumediumList: {
    loading: boolean;
    success: boolean;
    error: any | null;
    edumediums: [];
  };
}
const initialState = {
  classList: {
    loading: false,
    success: false,
    error: null,
    classes: [],
  },
  sectionList: {
    loading: false,
    success: false,
    error: null,
    sections: [],
  },
  branchList: {
    loading: false,
    success: false,
    error: null,
    branches: [],
  },
  shiftList: {
    loading: false,
    success: false,
    error: null,
    shifts: [],
  },
  teacherList: {
    loading: false,
    success: false,
    error: null,
    teachers: [],
  },
  sessionList: {
    loading: false,
    success: false,
    error: null,
    sessions: [],
  },
  bloodGroupList: {
    loading: false,
    success: false,
    error: null,
    bloodGroups: [],
  },
  religionList: {
    loading: false,
    success: false,
    error: null,
    religions: [],
  },
  genderList: {
    loading: false,
    success: false,
    error: null,
    genders: [],
  },
  countryList: {
    loading: false,
    success: false,
    error: null,
    countries: [],
  },
  stateList: {
    loading: false,
    success: false,
    error: null,
    states: [],
  },
  cityList: {
    loading: false,
    success: false,
    error: null,
    cities: [],
  },
  nationalityList: {
    loading: false,
    success: false,
    error: null,
    nationalities: [],
  },
  amendtypeList: {
    loading: false,
    success: false,
    error: null,
    ammendTypes: [],
  },
  amendtreatList: {
    loading: false,
    success: false,
    error: null,
    amendtreats: [],
  },
  daytypeList: {
    loading: false,
    success: false,
    error: null,
    daytypes: [],
  },
  edumediumList: {
    loading: false,
    success: false,
    error: null,
    edumediums: [],
  },
} as LovState;

const lovSlice = createSlice({
  name: "classes",
  initialState,
  reducers: {
    resetClassList: (state) => {
      state.classList.loading = false;
      state.classList.success = false;
      state.classList.error = null;
      state.classList.classes = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLovClass.pending, (state, action) => {
        state.classList.loading = true;
      })
      .addCase(getLovClass.fulfilled, (state, action: PayloadAction<[]>) => {
        state.classList.loading = false;
        state.classList.success = true;
        state.classList.error = null;
        state.classList.classes = action.payload;
      })
      .addCase(getLovClass.rejected, (state, action: PayloadAction<any>) => {
        state.classList.loading = false;
        state.classList.success = false;
        state.classList.classes = [];
        state.classList.error = action.payload;
      })
      .addCase(getLovSection.pending, (state, action) => {
        state.sectionList.loading = true;
      })
      .addCase(getLovSection.fulfilled, (state, action: PayloadAction<[]>) => {
        state.sectionList.loading = false;
        state.sectionList.success = true;
        state.sectionList.error = null;
        state.sectionList.sections = action.payload;
      })
      .addCase(getLovSection.rejected, (state, action: PayloadAction<any>) => {
        state.sectionList.loading = false;
        state.sectionList.success = false;
        state.sectionList.sections = [];
        state.sectionList.error = action.payload;
      })
      .addCase(getLovBranch.pending, (state, action) => {
        state.branchList.loading = true;
      })
      .addCase(getLovBranch.fulfilled, (state, action: PayloadAction<[]>) => {
        state.branchList.loading = false;
        state.branchList.success = true;
        state.branchList.error = null;
        state.branchList.branches = action.payload;
      })
      .addCase(getLovBranch.rejected, (state, action: PayloadAction<any>) => {
        state.branchList.loading = false;
        state.branchList.success = false;
        state.branchList.branches = [];
        state.branchList.error = action.payload;
      })
      .addCase(getLovShift.pending, (state, action) => {
        state.shiftList.loading = true;
      })
      .addCase(getLovShift.fulfilled, (state, action: PayloadAction<[]>) => {
        state.shiftList.loading = false;
        state.shiftList.success = true;
        state.shiftList.error = null;
        state.shiftList.shifts = action.payload;
      })
      .addCase(getLovShift.rejected, (state, action: PayloadAction<any>) => {
        state.shiftList.loading = false;
        state.shiftList.success = false;
        state.shiftList.shifts = [];
        state.shiftList.error = action.payload;
      })
      .addCase(getLovTeacher.pending, (state, action) => {
        state.teacherList.loading = true;
      })
      .addCase(getLovTeacher.fulfilled, (state, action: PayloadAction<[]>) => {
        state.teacherList.loading = false;
        state.teacherList.success = true;
        state.teacherList.error = null;
        state.teacherList.teachers = action.payload;
      })
      .addCase(getLovTeacher.rejected, (state, action: PayloadAction<any>) => {
        state.teacherList.loading = false;
        state.teacherList.success = false;
        state.teacherList.teachers = [];
        state.teacherList.error = action.payload;
      })
      .addCase(getLovSession.pending, (state, action) => {
        state.sessionList.loading = true;
      })
      .addCase(getLovSession.fulfilled, (state, action: PayloadAction<[]>) => {
        state.sessionList.loading = false;
        state.sessionList.success = true;
        state.sessionList.error = null;
        state.sessionList.sessions = action.payload;
      })
      .addCase(getLovSession.rejected, (state, action: PayloadAction<any>) => {
        state.sessionList.loading = false;
        state.sessionList.success = false;
        state.sessionList.sessions = [];
        state.sessionList.error = action.payload;
      })
      .addCase(getLovBloodgroup.pending, (state, action) => {
        state.bloodGroupList.loading = true;
      })
      .addCase(
        getLovBloodgroup.fulfilled,
        (state, action: PayloadAction<[]>) => {
          state.bloodGroupList.loading = false;
          state.bloodGroupList.success = true;
          state.bloodGroupList.error = null;
          state.bloodGroupList.bloodGroups = action.payload;
        }
      )
      .addCase(
        getLovBloodgroup.rejected,
        (state, action: PayloadAction<any>) => {
          state.bloodGroupList.loading = false;
          state.bloodGroupList.success = false;
          state.bloodGroupList.bloodGroups = [];
          state.bloodGroupList.error = action.payload;
        }
      )
      .addCase(getLovReligion.pending, (state, action) => {
        state.religionList.loading = true;
      })
      .addCase(getLovReligion.fulfilled, (state, action: PayloadAction<[]>) => {
        state.religionList.loading = false;
        state.religionList.success = true;
        state.religionList.error = null;
        state.religionList.religions = action.payload;
      })
      .addCase(getLovReligion.rejected, (state, action: PayloadAction<any>) => {
        state.religionList.loading = false;
        state.religionList.success = false;
        state.religionList.religions = [];
        state.religionList.error = action.payload;
      })
      .addCase(getLovGender.pending, (state, action) => {
        state.genderList.loading = true;
      })
      .addCase(getLovGender.fulfilled, (state, action: PayloadAction<[]>) => {
        state.genderList.loading = false;
        state.genderList.success = true;
        state.genderList.error = null;
        state.genderList.genders = action.payload;
      })
      .addCase(getLovGender.rejected, (state, action: PayloadAction<any>) => {
        state.genderList.loading = false;
        state.genderList.success = false;
        state.genderList.genders = [];
        state.genderList.error = action.payload;
      })
      .addCase(getLovCountry.pending, (state, action) => {
        state.countryList.loading = true;
      })
      .addCase(getLovCountry.fulfilled, (state, action: PayloadAction<[]>) => {
        state.countryList.loading = false;
        state.countryList.success = true;
        state.countryList.error = null;
        state.countryList.countries = action.payload;
      })
      .addCase(getLovCountry.rejected, (state, action: PayloadAction<any>) => {
        state.countryList.loading = false;
        state.countryList.success = false;
        state.countryList.countries = [];
        state.countryList.error = action.payload;
      })
      .addCase(getLovState.pending, (state, action) => {
        state.stateList.loading = true;
      })
      .addCase(getLovState.fulfilled, (state, action: PayloadAction<[]>) => {
        state.stateList.loading = false;
        state.stateList.success = true;
        state.stateList.error = null;
        state.stateList.states = action.payload;
      })
      .addCase(getLovState.rejected, (state, action: PayloadAction<any>) => {
        state.stateList.loading = false;
        state.stateList.success = false;
        state.stateList.states = [];
        state.stateList.error = action.payload;
      })
      .addCase(getLovCity.pending, (state, action) => {
        state.cityList.loading = true;
      })
      .addCase(getLovCity.fulfilled, (state, action: PayloadAction<[]>) => {
        state.cityList.loading = false;
        state.cityList.success = true;
        state.cityList.error = null;
        state.cityList.cities = action.payload;
      })
      .addCase(getLovCity.rejected, (state, action: PayloadAction<any>) => {
        state.cityList.loading = false;
        state.cityList.success = false;
        state.cityList.cities = [];
        state.cityList.error = action.payload;
      })
      .addCase(getLovNationality.pending, (state, action) => {
        state.nationalityList.loading = true;
      })
      .addCase(
        getLovNationality.fulfilled,
        (state, action: PayloadAction<[]>) => {
          state.nationalityList.loading = false;
          state.nationalityList.success = true;
          state.nationalityList.error = null;
          state.nationalityList.nationalities = action.payload;
        }
      )
      .addCase(
        getLovNationality.rejected,
        (state, action: PayloadAction<any>) => {
          state.nationalityList.loading = false;
          state.nationalityList.success = false;
          state.nationalityList.nationalities = [];
          state.nationalityList.error = action.payload;
        }
      )
      .addCase(getLovAmendtype.pending, (state, action) => {
        state.amendtypeList.loading = true;
      })
      .addCase(
        getLovAmendtype.fulfilled,
        (state, action: PayloadAction<[]>) => {
          state.amendtypeList.loading = false;
          state.amendtypeList.success = true;
          state.amendtypeList.error = null;
          state.amendtypeList.ammendTypes = action.payload;
        }
      )
      .addCase(
        getLovAmendtype.rejected,
        (state, action: PayloadAction<any>) => {
          state.amendtypeList.loading = false;
          state.amendtypeList.success = false;
          state.amendtypeList.ammendTypes = [];
          state.amendtypeList.error = action.payload;
        }
      )
      .addCase(getLovAmendtreat.pending, (state, action) => {
        state.amendtreatList.loading = true;
      })
      .addCase(
        getLovAmendtreat.fulfilled,
        (state, action: PayloadAction<[]>) => {
          state.amendtreatList.loading = false;
          state.amendtreatList.success = true;
          state.amendtreatList.error = null;
          state.amendtreatList.amendtreats = action.payload;
        }
      )
      .addCase(
        getLovAmendtreat.rejected,
        (state, action: PayloadAction<any>) => {
          state.amendtreatList.loading = false;
          state.amendtreatList.success = false;
          state.amendtreatList.amendtreats = [];
          state.amendtreatList.error = action.payload;
        }
      )
      .addCase(getLovDaytype.pending, (state, action) => {
        state.daytypeList.loading = true;
      })
      .addCase(getLovDaytype.fulfilled, (state, action: PayloadAction<[]>) => {
        state.daytypeList.loading = false;
        state.daytypeList.success = true;
        state.daytypeList.error = null;
        state.daytypeList.daytypes = action.payload;
      })
      .addCase(getLovDaytype.rejected, (state, action: PayloadAction<any>) => {
        state.daytypeList.loading = false;
        state.daytypeList.success = false;
        state.daytypeList.daytypes = [];
        state.daytypeList.error = action.payload;
      })
      .addCase(getLovEdumedium.pending, (state, action) => {
        state.edumediumList.loading = true;
      })
      .addCase(
        getLovEdumedium.fulfilled,
        (state, action: PayloadAction<[]>) => {
          state.edumediumList.loading = false;
          state.edumediumList.success = true;
          state.edumediumList.error = null;
          state.edumediumList.edumediums = action.payload;
        }
      )
      .addCase(
        getLovEdumedium.rejected,
        (state, action: PayloadAction<any>) => {
          state.edumediumList.loading = false;
          state.edumediumList.success = false;
          state.edumediumList.edumediums = [];
          state.edumediumList.error = action.payload;
        }
      );
  },
});

export const selectCount = (state: RootState) => state.lov;

export const { resetClassList } = lovSlice.actions;

export const lovReducer = lovSlice.reducer;
