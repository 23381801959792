import { FC } from "react";
import Skeleton from "react-loading-skeleton";

export interface DataTableProps {
  skeletonNumberOfRows?: number;
  skeletonHeight?: number;
}

export const CardSkeleton: FC<DataTableProps> = ({
  skeletonNumberOfRows = 6,
  skeletonHeight = 35,
}) => {
  return (
    <>
      <table className="table table-sm table-hover mb-0">
        <tbody>
          <tr>
            <td style={{ width: "10%" }}>
              <Skeleton height={skeletonHeight} />
            </td>
            <td></td>
            <td></td>
            <td style={{ width: "25%" }}></td>
          </tr>
          {Array.from({ length: skeletonNumberOfRows }, (_, index) => (
            <tr>
              <td colSpan={5}>
                <Skeleton key={index} height={skeletonHeight} />
              </td>
            </tr>
          ))}
          <tr>
            <td style={{ width: "10%" }}>
              <Skeleton height={skeletonHeight} />
            </td>
            <td></td>
            <td></td>
            <td style={{ width: "25%" }}>
              <Skeleton height={skeletonHeight} />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
