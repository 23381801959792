import { FC, Fragment, useState } from "react";
import { Col, Form, Row, InputGroup } from "react-bootstrap";
import { ErrorMessage, useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";

interface FormDatepickerProps {
  label: string;
  name: string;
  values: any;
  errors: any;
  touched: any;
  handleChange?: any | null;
  label_md?: number;
  input_md?: number;
  handleBlur: any;
  disabled?: boolean;
  //   initialDate: Date;
  dateFormat: string;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number;
  timeCaption?: string;
}
const FormDatepicker: FC<FormDatepickerProps> = ({
  disabled,
  label,
  name,
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  label_md,
  input_md,
  //   initialDate,
  dateFormat,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  timeCaption,
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);
  return (
    <Fragment>
      <Col className="mb-auto" md={label_md ?? 3}>
        <Form.Label className="form-label mg-b-0 mb-auto">{label}</Form.Label>
      </Col>
      <Col md={input_md ?? 9} className=" mg-t-5 mg-md-t-0">
        <InputGroup className="input-group reactdate-pic">
          <InputGroup.Text className="input-group-text pb-0">
            <i className="far fa-calendar"></i>
          </InputGroup.Text>
          <DatePicker
            autoComplete="off"
            className={`form-control form-control-sm ${
              errors[name] && touched[name] && "is-invalid"
            }`}
            disabled={disabled ? true : false}
            value={values[name]}
            onBlur={handleBlur}
            id={name}
            name={name}
            dateFormat={dateFormat}
            selected={(field.value && new Date(field.value)) || null}
            onChange={(val) => {
              if (handleChange != null) {
                handleChange(val);
              }
              setFieldValue(field.name, val);
            }}
            showTimeSelect={showTimeSelect}
            showTimeSelectOnly={showTimeSelectOnly}
            timeIntervals={timeIntervals}
            timeCaption={timeCaption}
          />
        </InputGroup>
        <ErrorMessage name={name} component="div" className="text-danger" />
      </Col>
    </Fragment>
  );
};

export default FormDatepicker;
