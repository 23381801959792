import { getResponse } from "../common/api.service";
import types from "../common/types";

export const getLovClass = getResponse(
  "lov/getClass",
  "/admin/lov/class",
  types.request.List,
  "lov"
);

export const getLovSection = getResponse(
  "lov/getSection",
  "/admin/lov/section",
  types.request.List,
  "lov"
);

export const getLovBranch = getResponse(
  "lov/getBranch",
  "/admin/lov/branch",
  types.request.List,
  "lov"
);

export const getLovShift = getResponse(
  "lov/getShift",
  "/admin/lov/shift",
  types.request.List,
  "lov"
);

export const getLovTeacher = getResponse(
  "lov/getTeacher",
  "/admin/lov/teacher",
  types.request.List,
  "lov"
);

export const getLovSession = getResponse(
  "lov/getSession",
  "/admin/lov/session",
  types.request.List,
  "lov"
);

export const getLovBloodgroup = getResponse(
  "lov/getBloodgroup",
  "/admin/lov/bloodgroup",
  types.request.List,
  "lov"
);

export const getLovReligion = getResponse(
  "lov/getReligion",
  "/admin/lov/religion",
  types.request.List,
  "lov"
);

export const getLovGender = getResponse(
  "lov/getGender",
  "/admin/lov/gender",
  types.request.List,
  "lov"
);

export const getLovCountry = getResponse(
  "lov/getLovCountry",
  "/admin/lov/country",
  types.request.List,
  "lov"
);

export const getLovState = getResponse(
  "lov/getLovState",
  "/admin/lov/state",
  types.request.List,
  "lov"
);

export const getLovCity = getResponse(
  "lov/getLovCity",
  "/admin/lov/city",
  types.request.List,
  "lov"
);

export const getLovNationality = getResponse(
  "lov/getLovNationality",
  "/admin/lov/nationality",
  types.request.List,
  "lov"
);

export const getLovAmendtype = getResponse(
  "lov/getLovAmendtype",
  "/admin/lov/amendtype",
  types.request.List,
  "lov"
);

export const getLovAmendtreat = getResponse(
  "lov/getLovAmendtreat",
  "/admin/lov/amendtreat",
  types.request.List,
  "lov"
);

export const getLovDaytype = getResponse(
  "lov/getLovDaytype",
  "/admin/lov/daytype",
  types.request.List,
  "lov"
);

export const getLovEdumedium = getResponse(
  "lov/getLovEdumedium",
  "/admin/lov/edumedium",
  types.request.List,
  "lov"
);
