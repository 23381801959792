import { FC, useEffect, useState } from "react";

import { Card, Col, Row, Button, ProgressBar } from "react-bootstrap";
import BreadcrumbHeader from "../../../components/common/BreadcrumbHeader";
import { ClassPayload, addClass } from "../../../features/classes/classService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, FormikHelpers, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { Chart as ChartJS, registerables } from "chart.js";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { resetSave } from "../../../features/classes/classSlice";

import { FormInput, FormDatepicker } from "../../../components/forms";
import { Bar, Doughnut } from "react-chartjs-2";
import {
  BasicTable,
  Column,
} from "../../../components/tables/DataTables/Basictable";

interface Values {
  class_name: string;
  level_id: string;
  description: string;
  is_active: boolean;
}

const validationSchema = Yup.object({
  class_name: Yup.string().required("Required"),
  level_id: Yup.string().required("Required"),
  description: Yup.string().nullable(),
  is_active: Yup.boolean().required("Required"),
});

export const dchart = {
  labels: ["Present", "Absent", "Leave"],
  //labels color

  datasets: [
    {
      data: [20, 70, 10],
      backgroundColor: ["#42c181", "#4c85d9", "#ffbf28"],
    },
  ],
  options: {
    plugins: {
      datalabels: {
        formatter: (value: number) => {
          return value + "%";
        },
        color: "#fff",
      },
    },
  },
  hoverOffset: 4,
};

export const Horizontalbarchartdata2 = {
  labels: [
    "Saturday",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
  ],
  datasets: [
    {
      data: [12, 39, 20, 10, 25, 18, 12, 44],
      backgroundColor: "#ffbf28",
    },
    {
      data: [22, 30, 25, 30, 20, 40, 65],
      backgroundColor: "#42c181",
    },
    {
      data: [33, 22, 11, 33, 44, 55, 22],
      backgroundColor: "#eb8989",
    },
  ],
};

export const Horizontalbarchart2: any = {
  indexAxis: "x",
  elements: {
    bar: {
      borderWidth: 3,
    },
  },
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: "",
    },
  },
};
const COLUMNS: Column[] = [
  {
    header: "Student Name",
    accessor: "student_name",
    className: "wd-20p borderrigth",
  },
  {
    header: "Present",
    accessor: "present",
    className: "wd-25p borderrigth",
  },

  {
    header: "1",
    accessor: "shift_1",
    className: "borderrigth text-center",
    Cell: ({ row }: any) => (
      <span className="w-100 text-center">{row.original.shift_1}</span>
    ),
  },

  {
    header: "2",
    accessor: "shift_2",
    className: "borderrigth text-center",
    Cell: ({ row }: any) => (
      <span className="w-100 text-center">{row.original.shift_2}</span>
    ),
  },
  {
    header: "3",
    accessor: "shift_3",
    className: "borderrigth text-center",
    Cell: ({ row }: any) => (
      <span className="w-100 text-center">{row.original.shift_3}</span>
    ),
  },
  {
    header: "4",
    accessor: "shift_4",
    className: "borderrigth text-center",
    Cell: ({ row }: any) => (
      <span className="w-100 text-center">{row.original.shift_4}</span>
    ),
  },
  {
    header: "5",
    accessor: "shift_5",
    className: "borderrigth text-center",
    Cell: ({ row }: any) => (
      <span className="w-100 text-center">{row.original.shift_5}</span>
    ),
  },
];
ChartJS.register(...registerables);
const TeacherAttendanceIndexScreen: FC = () => {
  const dispatch = useAppDispatch();
  const [initialValues, setInitialValues] = useState<Values>({
    class_name: "",
    level_id: "",
    description: "",
    is_active: true,
  });
  const { loading, error, success } = useAppSelector(
    (state) => state.class.save
  );
  const breadcrumbItems = [
    { text: "Teacher", href: "/attendances/teachers" },
    { text: "Attendance", isActive: true },
  ];
  useEffect(() => {
    if (error != null) {
      toast.error(error.message);
    }
    // if (success) {
    //   toast("Successful!");
    //   dispatch(resetSave());
    //   //navigate("/classes/list");
    // }
  }, [dispatch]);

  const handleSubmit = (
    values: Values,
    { resetForm }: FormikHelpers<Values>
  ) => {
    const payload: ClassPayload = {
      data: {
        class: [
          {
            class_name: values.class_name,
            class_lavel: parseInt(values.level_id),
            class_description: values.description,
            is_active: values.is_active ? 1 : 0,
          },
        ],
      },
    };
    dispatch(addClass(payload))
      // .unwrap()
      .then(() => {
        resetForm();
        toast("Successful!");
        dispatch(resetSave());
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  return (
    <div>
      <ToastContainer />
      <BreadcrumbHeader
        mainContentTitle="Teacher Attendance"
        breadcrumbItems={breadcrumbItems}
      />
      <Row>
        <Col lg={4} md={4}>
          <Card>
            <Card.Body>
              <div className="main-content-label mg-b-5">Attendance</div>
              <div className="">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <FormikForm noValidate onSubmit={handleSubmit}>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormInput
                          label="Class"
                          name="class_name"
                          type="text"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={true}
                          label_md={5}
                          input_md={7}
                          placeholder="Enter class name"
                        />
                      </Row>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormInput
                          label="Section"
                          name="section_id"
                          type="text"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={true}
                          label_md={5}
                          input_md={7}
                          placeholder="Enter Section name"
                        />
                      </Row>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormInput
                          label="Shift"
                          name="shift_id"
                          type="text"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={true}
                          label_md={5}
                          input_md={7}
                          placeholder="Enter Shift"
                        />
                      </Row>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormDatepicker
                          label="Date"
                          name="student_dob"
                          dateFormat="yyyy-mm-dd"
                          values={values}
                          errors={errors}
                          touched={touched}
                          label_md={5}
                          input_md={7}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      </Row>
                      <Row className="row-xs align-items-center mg-b-5">
                        <FormInput
                          label="Number of Student"
                          name="number_of_student"
                          type="text"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={true}
                          label_md={5}
                          input_md={7}
                          placeholder="Number of Student"
                        />
                      </Row>
                      <Row>
                        <Col sm={12} md={12}>
                          <Card className=" overflow-hidden">
                            <Card.Body>
                              {/* <div className="main-content-label mg-b-5">
                                Donut Chart
                              </div>
                              <p className="mg-b-20">
                                Basic Charts Of Nowa template.
                              </p> */}
                              <div className="chartjs-wrapper-demo ">
                                <Doughnut
                                  data={dchart}
                                  id="chartDonut"
                                  className="chartjs-render-monitor w-250 h-275"
                                />
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className="text-wrap">
                            <div className="">
                              <span className="tx-uppercase tx-11 d-block mg-b-5">
                                Todays Attendance
                              </span>

                              <div className="progress mg-b-10">
                                <ProgressBar
                                  className="progress-bar-lg"
                                  now={80}
                                  variant="success"
                                  label="80%"
                                ></ProgressBar>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </FormikForm>
                  )}
                </Formik>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={8} md={8}>
          <Card style={{ height: "97%" }}>
            <Card.Body>
              <div className="main-content-label mg-b-5">Statistics</div>
              <div>
                <Row>
                  <Col md={12}>
                    <div className="text-wrap">
                      <div className="">
                        <span className="tx-uppercase tx-11 d-block mg-b-5">
                          This Week Present
                        </span>

                        <div className="progress mg-b-10">
                          <ProgressBar
                            className="progress-bar-lg"
                            now={80}
                            variant="success"
                            label="80%"
                          ></ProgressBar>
                        </div>
                      </div>
                      <div className="">
                        <span className="tx-uppercase tx-11 d-block mg-b-5">
                          This Month Present
                        </span>

                        <div className="progress mg-b-10">
                          <ProgressBar
                            className="progress-bar-lg"
                            now={60}
                            variant="info"
                            label="80%"
                          ></ProgressBar>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12}>
                    <Card className=" overflow-hidden">
                      <Card.Body>
                        {/* <div className="main-content-label mg-b-5">
                                Horizontal Bar Chart
                              </div>
                              <p className="mg-b-20">
                                Basic Charts Of Nowa template.
                              </p> */}
                        <div className="chartjs-wrapper-demo">
                          {/*<canvas id="chartBar5"></canvas>*/}

                          <Bar
                            style={{ height: "100%" }}
                            options={Horizontalbarchart2}
                            data={Horizontalbarchartdata2}
                            height={130}
                            className="barchart"
                            id="chartBar5"
                          />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className=" row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div>
                <h6 className="main-content-label mb-1">All Sections</h6>
              </div>
              <div className="table-responsive">
                <BasicTable
                  columns={COLUMNS}
                  data={[
                    {
                      student_name: "Rahim",
                      present: "15",
                      shift_1: "P",
                      shift_2: "P",
                      shift_3: "P",
                      shift_4: "P",
                      shift_5: "P",
                    },
                    {
                      student_name: "Karim",
                      present: "15",
                      shift_1: "A",
                      shift_2: "P",
                      shift_3: "P",
                      shift_4: "A",
                      shift_5: "P",
                    },
                    {
                      student_name: "Jenny",
                      present: "15",
                      shift_1: "P",
                      shift_2: "A",
                      shift_3: "P",
                      shift_4: "A",
                      shift_5: "P",
                    },
                  ]}
                  loading={loading}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TeacherAttendanceIndexScreen;
