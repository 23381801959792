import { FC, Fragment } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ErrorMessage } from "formik";

interface FormInputProps {
  label?: string | null;
  name: string;
  type: string;
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
  label_md?: number;
  input_md?: number;
  disabled?: boolean;
  placeholder: string;
}

const FormInput: FC<FormInputProps> = ({
  disabled,
  label,
  name,
  type,
  errors,
  touched,
  values,
  placeholder,
  handleChange,
  handleBlur,
  label_md,
  input_md,
}) => {
  return (
    <Fragment>
      {label != null && (
        <Col className="mb-auto" md={label_md ?? 3}>
          <Form.Label className="form-label mg-b-0 mb-auto">{label}</Form.Label>
        </Col>
      )}
      <Col md={input_md ?? 9} className=" mg-t-5 mg-md-t-0">
        <Form.Control
          className={`form-control-sm ${
            errors[name] && touched[name] && "is-invalid"
          }`}
          disabled={disabled ? true : false}
          value={values[name]}
          onChange={handleChange}
          onBlur={handleBlur}
          id={name}
          name={name}
          type={type}
          placeholder={placeholder}
        />
        <ErrorMessage name={name} component="div" className="text-danger" />
      </Col>
    </Fragment>
  );
};

export default FormInput;
