import { FC, Fragment } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ErrorMessage } from "formik";

interface FormTextAreaProps {
  label: string;
  name: string;
  row: number;
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
  disabled?: boolean;
  placeholder: string;
}

const FormTextArea: FC<FormTextAreaProps> = ({
  disabled,
  label,
  name,
  row,
  errors,
  touched,
  values,
  placeholder,
  handleChange,
  handleBlur,
}) => {
  return (
    <Fragment>
      <Col className="mb-auto" md={3}>
        <Form.Label className="form-label mg-b-0 mb-auto">{label}</Form.Label>
      </Col>
      <Col md={9} className=" mg-t-5 mg-md-t-0">
        <textarea
          className={`form-control form-control-sm ${
            errors[name] && touched[name] && "is-invalid"
          }`}
          disabled={disabled ? true : false}
          value={values[name]}
          onChange={handleChange}
          onBlur={handleBlur}
          id={name}
          name={name}
          placeholder={placeholder}
          rows={row}
        ></textarea>
        <ErrorMessage name={name} component="div" className="text-danger" />
      </Col>
    </Fragment>
  );
};

export default FormTextArea;
