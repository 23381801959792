import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Card, Col, Row, Spinner, Button } from "react-bootstrap";
import { getSections } from "../../features/sections/sectionService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  BasicTable,
  Column,
} from "../../components/tables/DataTables/Basictable";
import BreadcrumbHeader from "../../components/common/BreadcrumbHeader";

const SectionIndexScreen = () => {
  const navigate = useNavigate();
  const handleEdit = (id: any) => {
    console.log(`Edit row with ID: ${id}`);
    navigate("/sections/edit/" + id);
  };

  const COLUMNS: Column[] = [
    {
      header: "Section Name",
      accessor: "section_name",
      className: "wd-20p borderrigth",
    },
    {
      header: "Branch",
      accessor: "branch_id",
      className: "wd-25p borderrigth",
    },

    {
      header: "Class",
      accessor: "class_id",
      className: "wd-25p borderrigth",
    },

    {
      header: "Shift",
      accessor: "shift_id",
      className: "wd-25p borderrigth",
    },
    {
      header: "Description",
      accessor: "section_description",
      className: "wd-20p borderrigth",
    },
    {
      header: "Room Number",
      accessor: "section_room_number",
      className: "wd-20p borderrigth",
    },
    {
      header: "HR",
      accessor: "hr_id",
      className: "wd-20p borderrigth",
    },
    {
      header: "Status",
      accessor: "is_active",
      className: "wd-15p borderrigth text-center",
      Cell: ({ row }: any) => (
        <span className="w-100 text-center">
          {row.original.is_active === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      header: "Actions",
      accessor: "id", // Assuming 'id' is a unique identifier for each row
      className: "action-column d-flex flex-row justify-content-center",
      Cell: ({ row }: any) => (
        <span className="w-100 text-center">
          <Button
            variant="primary"
            size="sm"
            onClick={() => handleEdit(row.original.section_id)}
          >
             <i className="fa fa-pen"></i>
          </Button>
        </span>
      ),
    },
  ];
  const { loading, error, sections } = useAppSelector(
    (state) => state.section.list
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (error != null) {
      toast.error(error.message);
    }
    dispatch(
      getSections({
        section: {},
      })
    );
  }, [dispatch, error]);

  console.log(sections);
  const breadcrumbItems = [
    { text: "Section", href: "/sections/list" },
    { text: "All", isActive: true },
  ];
  const rightButton = (
    <Button
      variant="primary"
      className="mb-4 ml-2 btn btn-sm"
      onClick={() => navigate("/sections/entry")}
    >
      <span>
        <i className="fa fa-plus"></i>
        <span style={{ marginLeft: "5px !important" }}> New</span>
      </span>
    </Button>
  );
  return (
    <div>
      <BreadcrumbHeader
        mainContentTitle="Section Info"
        breadcrumbItems={breadcrumbItems}
      />
      <div className="main-container container-fluid">
        {/* <!-- Row --> */}
        <Row className=" row-sm">
          <Col lg={12}>
            <Card className="custom-card overflow-hidden">
              <Card.Body>
                <div>
                  <h6 className="main-content-label mb-1">All Sections</h6>
                </div>
                <div className="table-responsive">
                  <BasicTable
                    columns={COLUMNS}
                    data={sections}
                    RightButton={rightButton}
                    loading={loading}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <!-- End Row --> */}
      </div>
    </div>
  );
};

export default SectionIndexScreen;
