import { Card, Col } from "react-bootstrap";
import BreadcrumbHeader from "../../../components/common/BreadcrumbHeader";
import { useParams } from "react-router-dom";
import { StudentEditFormWizard } from "../components/SutentEditFormWizard";
import { FC } from "react";
import { ToastContainer } from "react-toastify";

const StudentEditScreen: FC = () => {
  const { id } = useParams();
  const breadcrumbItems = [
    { text: "Student", href: "/students" },
    { text: "Edit", isActive: true },
  ];
  return (
    <div>
      <ToastContainer />
      <BreadcrumbHeader
        mainContentTitle="Student Information"
        breadcrumbItems={breadcrumbItems}
      />
      <Col className="mt-5" lg={12} md={12}>
        <Card>
          <Card.Body>
            <div id="wizard1" className="border">
              <StudentEditFormWizard id={id} />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};
export default StudentEditScreen;
