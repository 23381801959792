import { FC, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import {
  FormCheckBox,
  FormDatepicker,
  FormInput,
  FormSelect,
} from "../../../components/forms";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

import {
  getLovBloodgroup,
  getLovGender,
  getLovReligion,
  getLovNationality,
} from "../../lov/lovService";

interface GeneralInfoSectionProps {
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
}

const GeneralInfoSection: FC<GeneralInfoSectionProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  const dispatch = useAppDispatch();
  const { bloodGroupList, genderList, religionList, nationalityList } =
    useAppSelector((state) => state.lov);

  useEffect(() => {
    if (bloodGroupList.bloodGroups.length === 0) {
      dispatch(getLovBloodgroup({}));
    }
    if (genderList.genders.length === 0) {
      dispatch(getLovGender({}));
    }
    if (religionList.religions.length === 0) {
      dispatch(getLovReligion({}));
    }
    if (nationalityList.nationalities.length === 0) {
      dispatch(getLovNationality({}));
    }
  }, [dispatch]);
  return (
    <section className="card-body Basicwizard ">
      <div className="main-content-label mg-b-5">General Info</div>
      <div className="pd-30 pd-sm-20">
        <Row className="row-xs align-items-center mg-b-5">
          <FormInput
            label="*Student name"
            name="student_name"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter student name"
          />
        </Row>
        <Row className="row-xs align-items-center mg-b-5">
          <FormInput
            label="*Short name"
            name="short_name"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter short name"
          />
        </Row>
        <Row className="row-xs align-items-center mg-b-5">
          <FormDatepicker
            label="*Date of Birth"
            name="student_dob"
            dateFormat="yyyy-mm-dd"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={null}
            handleBlur={handleBlur}
          />
        </Row>
        <Row className="row-xs align-items-center mg-b-5">
          <FormSelect
            label="*Gender"
            name="gender_id"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            loading={genderList.loading}
            options={genderList.genders.map((item: any) => {
              return {
                value: item.list_item_id,
                label: item.list_item_name,
              };
            })}
            placeholder="Select Gender"
            label_md={3}
            input_md={3}
          />
          <FormSelect
            label="*Blood Group"
            name="blood_group_id"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            loading={bloodGroupList.loading}
            options={bloodGroupList.bloodGroups.map((item: any) => {
              return {
                value: item.list_item_id,
                label: item.list_item_name,
              };
            })}
            placeholder="Select Blood Group"
            label_md={3}
            input_md={3}
          />
        </Row>
        <Row className="row-xs align-items-center mg-b-5">
          <FormSelect
            label="*Religion"
            name="religion_id"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            loading={religionList.loading}
            options={religionList.religions.map((item: any) => {
              return {
                value: item.list_item_id,
                label: item.list_item_name,
              };
            })}
            placeholder="Select Religion"
            label_md={3}
            input_md={3}
          />
          <FormSelect
            label="*Nationality"
            name="nationality_id"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            loading={nationalityList.loading}
            options={nationalityList.nationalities.map((item: any) => {
              return {
                value: item.list_item_id,
                label: item.list_item_name,
              };
            })}
            placeholder="Select Nationality"
            label_md={3}
            input_md={3}
          />
        </Row>
        <Row className="row-xs align-items-center mg-b-5">
          <FormInput
            label="*Birth Certificate Number"
            name="birth_certificate_no"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter Certificate No"
          />
        </Row>
        <Row className="row-xs align-items-center mg-b-5">
          <FormInput
            label="*Student Code"
            name="student_code"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter Certificate No"
          />
        </Row>
      </div>
      <div className="main-content-label mg-b-5">Parents Information</div>
      <div className="pd-30 pd-sm-20">
        <Row className="row-xs align-items-center mg-b-5">
          <FormInput
            label="*Father's Name"
            name="father_name"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter Father's Name"
          />
        </Row>
        {/* Phone number + use as primary contact checkbox */}
        <Row className="row-xs align-items-center mg-b-5">
          <FormInput
            label="*Father's Phone"
            name="father_phone"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter Father's Name"
            label_md={3}
            input_md={4}
          />
          <Col md={5} className="mg-t-5 mg-md-t-0">
            <FormCheckBox
              label="Use as primary contact"
              name="is_primary_contact"
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </Col>
        </Row>
        {/* Email + use as primary contact checkbox */}
        <Row className="row-xs align-items-center mg-b-5">
          <FormInput
            label="*Father's Email"
            name="father_email"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter Father's email"
            label_md={3}
            input_md={4}
          />
          <Col md={5} className="mg-t-5 mg-md-t-0">
            <FormCheckBox
              label="Use as primary email"
              name="is_primary_email"
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </Col>
        </Row>
        {/* Mothers Section */}
        <Row className="row-xs align-items-center mg-b-5">
          <FormInput
            label="*Mother's Name"
            name="mother_name"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter Mother's Name"
          />
        </Row>
        {/* Phone number + use as primary contact checkbox */}
        <Row className="row-xs align-items-center mg-b-5">
          <FormInput
            label="*Mother's Phone"
            name="mother_phone"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter Mother's Name"
            label_md={3}
            input_md={4}
          />
          <Col md={5} className="mg-t-5 mg-md-t-0">
            <FormCheckBox
              label="Use as primary contact"
              name="is_primary_contact"
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </Col>
        </Row>
        {/* Email + use as primary contact checkbox */}
        <Row className="row-xs align-items-center mg-b-5">
          <FormInput
            label="*Mother's Email"
            name="mother_email"
            type="text"
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter mother's email"
            label_md={3}
            input_md={4}
          />
          <Col md={5} className="mg-t-5 mg-md-t-0">
            <FormCheckBox
              label="Use as primary email"
              name="is_primary_email"
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default GeneralInfoSection;
